import { defineStore } from "pinia"
import { getServerUrl } from "@/common/urls";
import { OvertimeModel } from "@/services/entities/OvertimeModel";
import OvertimeDataFetcher from "@/services/connectors/overtimeConnector";

const overtime = new OvertimeDataFetcher(getServerUrl());

export const useOvertimeStore = defineStore("overtimeModel", {
    state: () => ({
      overtime: {
        data: {} as OvertimeModel,
        loader: false,
      }  
    }),
    actions: {
        async loadOvertimeModel(
            year: number,
        month: number,
        userId: string
    ): Promise<void> {
        try {
            this.overtime.loader = true;
            this.overtime.data = await overtime.getOvertimeInfo(year, month, userId);

        } catch (e: any) {
            this.overtime.loader = false;
        }
    },

    },
    getters: {
        getModel(): OvertimeModel {
            return this.overtime.data;
        },
        getModelLoaded(): boolean {
            return !this.overtime.loader;
        }
    }
});
