<template>
  <div class="small-table" v-if="settingsStore.getRatesLoaded">
    <RateTable :data="settingsStore.getRates" @edit="showEditRate" @delete="deleteRate"></RateTable>
    <div class="buttons">
      <button class="accent" @click="() => vfm.open(rateModalId)"><b>+</b> Add rate</button>
    </div>
  </div>
  <Loader v-else></Loader>
  <RateFormModal :value="state.value" :modal-id="rateModalId" @close="closeModal" @create="create" @edit="edit">
  </RateFormModal>
</template>

<script setup lang="ts">
import RateTable from '@/components/tables/RateTable.vue';
import { useSettingsStore } from '@/stores/setting';
import { onMounted, reactive } from 'vue';
import RateFormModal from '@/components/modals/RateFormModal.vue';
import { useVfm } from 'vue-final-modal';
import { RateItem } from '@/services/entities/RateItem';
import Loader from '@/components/util/Loader.vue';

const settingsStore = useSettingsStore();
const rateModalId = Symbol("rateModal");
const vfm = useVfm();

onMounted(async () => {
  await settingsStore.loadRates();
});

const state = reactive({
  value: {} as RateItem,
});

async function create() {
  await settingsStore.createRate(state.value);
  vfm.close(rateModalId)
  state.value = {} as RateItem;
}

async function edit() {
  await settingsStore.updateRate(state.value);
  vfm.close(rateModalId)
  state.value = {} as RateItem;
}

function showEditRate(rateId: string) {
  state.value = settingsStore.getRates.find(a => a.id == rateId);
  vfm.open(rateModalId);
}

async function closeModal() {
  vfm.close(rateModalId);
  state.value = {} as RateItem;
  settingsStore.loadRates();
}

async function deleteRate(rateId: string) {
  await settingsStore.deleteRate(rateId);
  state.value = {} as RateItem;
}

</script>

<style scoped lang="scss"></style>
