<template>
  <VueFinalModal
    class="modal"
    content-class="modal-content"
    overlay-transition="vfm-fade"
    :clickToClose="false"
    @click-outside="beforeClose"
  >
    <LockDetail
      :locks="props.locks"
      :userName="props.userName"
      :userId="props.userId"
      :year="props.year"
      :month="props.month"
      @close="emit('close')"
      @refreshUsers="emit('refreshUsers')"
    >
    </LockDetail>
    <ConfirmModal
      :modal-id="confirmModalId"
      text="You have unsaved changes, do you really want to leave this form?"
      confirm-text="Close form"
      @confirm="
        () => {
          vfm.close(confirmModalId);
          emit('close');
        }
      "
      @close="vfm.close(confirmModalId)"
    >
    </ConfirmModal>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { LockHistoryItem } from "@/services/entities/LockHistoryItem";

import { useVfm, VueFinalModal } from "vue-final-modal";
import { reactive } from "vue";
import ConfirmModal from "./ConfirmModal.vue";
import LockForm from "@/components/lock/LockForm.vue";
import LockDetail from "@/components/lock/LockDetail.vue";

const vfm = useVfm();
const confirmModalId = Symbol("confirmModalId");
const emit = defineEmits(["close", "refreshUsers"]);

const props = defineProps({
  locks: { Type: [] as LockHistoryItem[], default: [] as LockHistoryItem[] },
  userName: { Type: {} as string, default: "Unknown" as string },
  userId: { Type: {} as string, default: "" as string },
  year: { Type: {} as number },
  month: { Type: {} as number },
});

// const state = reactive({
//   value: {
//     dateFrom: new Date(),
//     dateTo: new Date(),
//     isLocked: false,
//     isPlaceholder: false,
//     lockedUserId: "",
//   },
// });

function beforeClose() {
  vfm.open(confirmModalId);
}
</script>

<style>
</style>