<template>
  <VueFinalModal class="comment-modal" content-class="modal-content" overlay-transition="vfm-fade"
    content-transition="vfm-fade">
    <div class="tittle">
      <span>Approve or reject</span>
    </div>
    <textarea v-model="state.comment" placeholder="Optional"></textarea>
    <div class="buttons">
      <button @click="emit('reject', state.comment)">Reject</button>
      <button class="accent" @click="emit('approve', state.comment)">Approve</button>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { VueFinalModal } from 'vue-final-modal';

const emit = defineEmits(['reject', 'approve']);

const state = reactive({
  comment: ""
});

</script>

<style scoped lang="scss">
textarea {
  width: calc(100% - 15px);
  max-width: calc(100% - 15px);;
}
</style>