import { defineStore } from "pinia"
import { ApprovalStatus, MonthlyApprovalItem } from "@/services/entities/Approval/MonthlyApprovalItem";
import MonthlyApprovalDataFetcher from "@/services/connectors/approvalConnector"
import { closeToast, showLoading, showSuccess, showToastError } from "@/toastification";
import { getServerUrl } from "@/common/urls";
import { parseError } from "@/common/util";

const approval = new MonthlyApprovalDataFetcher(getServerUrl());

export const useApprovalStore = defineStore("monthlyApprovalItem", {
   state: () => ({
    approval: {
        data: {} as MonthlyApprovalItem,
        loader: false,
    }
   }), 
   actions: {
    async loadApproval(
        year: number,
        month: number,
        userId: string
    ): Promise<void> {
        try {
            this.approval.loader = true;
            this.approval.data = await approval.getApproval(year, month, userId);
        } catch(e: any) {
            this.approval.loader = false;
        }
    },
    async saveApproval(approvalItem: MonthlyApprovalItem, actionAuthor: string) : Promise<void> {
        try {
            // TODO: message by action and status
            let action = approvalItem.status == ApprovalStatus.SentForApproval ? "Sending for approval" :
                approvalItem.status == ApprovalStatus.Approved ? "Approving..." :
                approvalItem.status == ApprovalStatus.Rejected ? "Rejecting..." :
                "Cooking something...";
            let action2 = approvalItem.status == ApprovalStatus.SentForApproval ? "Sent for approval" :
            approvalItem.status == ApprovalStatus.Approved ? "Approved" :
            approvalItem.status == ApprovalStatus.Rejected ? "Rejected" :
            "Something has been cooked";
            const toast = showLoading(action);
            await approval.updateApproval(approvalItem, actionAuthor);
            closeToast(toast);
            showSuccess(action2);
        } catch(e: any){
            showToastError("Failed to save approval: " + parseError(e));
            console.error(e);
        }
    },
   },
   getters: {
    getAll(): MonthlyApprovalItem {
        return this.approval.data;
    },
    getAllLoaded(): boolean {
        return !this.approval.loader;
    }
   }
});