<template>
    <Teleport to="body">
        <div class="loading-background">
            <Transition name="slide-fade">
                <span v-if="state.iteration % 2 == 0">{{ state.text }}</span>
                <span v-else>{{ state.text2 }}</span>
            </Transition>
            <LoadingDots class="loading"></LoadingDots>
        </div>
    </Teleport>
</template>
<script setup lang="ts">
import { reactive } from "vue";
import LoadingDots from "./LoadingDots.vue";
import { loadingTexts } from "@/common/util";

const max = loadingTexts.length;
const state = reactive({
    iteration: 0,
    text: "Please do not leave this window",
    text2: loadingTexts[getRandomInt(max)]
})

setInterval(() => {
    const id = getRandomInt(max);
    if (state.iteration % 2 != 0) {
        state.text2 = loadingTexts[id]
    }
    state.iteration++;
}, 5000)

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

</script>

<style scoped lang="scss">
.loading-background {
    top: 0;
    backdrop-filter: blur(6px);
    height: 100%;
    width: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    span {
        font-size: 22px;
        width: 545px;
        position: absolute;
    }

    .loading {
        margin-top: 80px;
    }
}
</style>
<style lang="scss">
.slide-fade-enter-active {
    transition: all 1s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
    transform: translateX(-400px);
}

.slide-fade-leave-to {
    transform: translateX(400px);
    opacity: 0;
}
</style>