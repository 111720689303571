<template>
  <div>
    <div class="header">
      <span class="title">Projects</span>
    </div>
    <div class="project-grid">
      <TileCard id="project-table" class="project-body table" :loaded="projectStore.getAllLoaded">
        <template #footer>
          <div class="buttons">
            <button class="accent" @click="() => vfm.open(projectModalId)">
              <b>+</b>Create project
            </button>
          </div>
        </template>
        <template #body>
          <ProjectTable :projects="projectStore.getAll"
            @detail="(index) => { state.detail = projectStore.getAll.find(a => a.id == index) }">
          </ProjectTable>
        </template>
      </TileCard>
      <TileCard id="project-detail" class="project-body detail" v-if="state.detail">
        <template #body>
          <ProjectDetail :project="state.detail" @edit="() => vfm.open(editServiceModalId)" @delete="deleteProject">
          </ProjectDetail>
        </template>
      </TileCard>
      <div class="project-body assignment" v-if="state.detail?.projectTypeId != 2">
        <TileCard id="project-user" v-if="state.detail">
          <template #body>
            <div class="header">
              <span class="title">
                User assignment</span>
            </div>
            <UserAssignment :users="state.detail.usersProject" @save="(a) => addUsers(a)"
              :project-start="state.detail.projectStart" :project-end="state.detail.projectEnd"></UserAssignment>
          </template>
        </TileCard>
        <TileCard id="project-activity" v-if="state.detail">
          <template #body>
            <div class="header">
              <span class="title">
                Activity assignment</span>
            </div>
            <ActivityAssignment :definitions="state.detail.definitions" @save="addDefinition" @delete="deleteDefinition" @edit="editDefinition">
            </ActivityAssignment>
          </template>
        </TileCard>
      </div>
    </div>
    <CreateProjectModal :modal-id="projectModalId"
      @close="(id) => { vfm.close(projectModalId); state.detail = projectStore.getAll.find(a => a.id == id) }">
    </CreateProjectModal>
    <EditProjectModal :value="state.detail" :modal-id="editServiceModalId" @close="() => vfm.close(editServiceModalId)">
    </EditProjectModal>
  </div>
</template>

<script setup lnag="ts">
import ProjectTable from "@/components/tables/ProjectTable.vue";
import { onMounted, reactive } from "vue";
import { useProjectStore } from "@/stores/project";
import TileCard from "@/components/util/TileCard.vue";
import { useVfm } from "vue-final-modal";
import CreateProjectModal from "@/components/modals/CreateProjectModal.vue";
import EditProjectModal from "@/components/modals/EditProjectModal.vue";
import ProjectDetail from "@/components/project/ProjectDetail.vue";
import UserAssignment from "@/components/user/UserAssignment.vue"
import ActivityAssignment from "@/components/activity/ActivityAssignment.vue"
import { useSettingsStore } from "@/stores/setting";

const projectStore = useProjectStore();
const settingsStore = useSettingsStore();

const vfm = useVfm();
const projectModalId = Symbol("projectModalId");
const editServiceModalId = Symbol("editServiceModalId");

const state = reactive({
  detail: null,
});

async function editDefinition(definition) {
  await projectStore.updateDefinition(state.detail.id, definition);
}

async function addUsers(users) {
  await projectStore.updateProjectUsers(state.detail.id, users);
}

async function addDefinition(definitions) {
  await projectStore.updateDefinitions(state.detail.id, definitions);
  await projectStore.loadProjects();
  state.detail = projectStore.getAll.find(a => a.id == state.detail.id);
}

async function deleteDefinition(definition) {
  await projectStore.deleteDefinition(state.detail.id, definition)
}

async function deleteProject(projectId) {
  await projectStore.deleteProject(projectId);
  state.detail = null;
}

onMounted(async () => {
  await projectStore.loadProjects();
  await settingsStore.loadServices();
});
</script>

<style scoped lang="scss">
.project-activity,
.project-body {
  .header {
    margin-left: 0;
  }

  .title {
    font-size: 16px;
    margin-left: 0;
    border-bottom: 1px solid #BABABA;
    margin-bottom: 5px;
  }
}

.project-grid {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  height: calc(100vh - 150px);
  overflow: auto;

  &>.project-body {
    display: flex;
    justify-content: row;
    min-width: 320px;
  }

  .table {
    width: calc(40% - 40px);
  }

  .detail {
    width: calc(20% - 40px);
  }

  .assignment {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 150px);
    min-height: 100px;

    &>div {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>