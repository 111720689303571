<template>
  <div class="small-table" v-if="settingsStore.getDivisionsLoaded">
    <DivisionTable :data="settingsStore.getDivisions" @edit="showEditDivision" @delete="deleteDivision"></DivisionTable>
    <div class="buttons">
      <button class="accent" @click="() => vfm.open(divisionModalId)"><b>+</b>Add division</button>
    </div>
  </div>
  <Loader v-else></Loader>
  <DivisionFormModal :value="state.value" :modal-id="divisionModalId" @close="closeModal" @create="create" @edit="edit">
  </DivisionFormModal>
</template>

<script setup lang="ts">
import DivisionTable from '@/components/tables/DivisionTable.vue';
import DivisionFormModal from '@/components/modals/DivisionFormModal.vue';
import { DivisionItem } from '@/services/entities/DivisionItem';
import { useSettingsStore } from '@/stores/setting';
import { onMounted, reactive } from 'vue';
import { useVfm } from 'vue-final-modal';
import Loader from '@/components/util/Loader.vue';

const settingsStore = useSettingsStore();
const divisionModalId = Symbol("divisionModalId");
const vfm = useVfm();

onMounted(async () => {
  await settingsStore.loadDivisions();
})

const state = reactive({
  value: {} as DivisionItem,
});

async function create() {
  await settingsStore.createDivision(state.value);
  vfm.close(divisionModalId)
  state.value = {} as DivisionItem;
  await settingsStore.loadDivisions();
}

async function edit() {
  await settingsStore.updatingDivision(state.value);
  vfm.close(divisionModalId)
  state.value = {} as DivisionItem;
}

function showEditDivision(id: number) {
  state.value = settingsStore.getDivisions.find(a => a.id == id);
  vfm.open(divisionModalId);
}

async function closeModal() {
  vfm.close(divisionModalId);
  state.value = {} as DivisionItem;
  settingsStore.loadRates();
}

async function deleteDivision(id: number) {
  await settingsStore.deleteDivision(id);
  state.value = {} as DivisionItem;
}
</script>

<style scoped lang="scss"></style>
