import ApiConnector from "../apiConnector";
import { MonthlyApprovalItem } from "../entities/Approval/MonthlyApprovalItem";

class MonthlyApprovalDataFetcher {
    private readonly apiConnector: ApiConnector;

    constructor(baseURL: string){
        this.apiConnector = new ApiConnector(baseURL);
    }

    public async getApproval(year: number, month: number, userId: string){
        return await this.apiConnector.get<any>(
            `approval/${year}/${month}/${userId}`
        );
    }

    public async updateApproval(approval: MonthlyApprovalItem, actionAuthor: string){
        return await this.apiConnector.put<void>(`approval/${actionAuthor}`, approval);
    }

    public async getUsersForApproval(year: number, month: number, userId: string){
        return await this.apiConnector.get<any>(`approval/users/${year}/${month}/${userId}`);
    }
}

export default MonthlyApprovalDataFetcher;