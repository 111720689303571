import ApiConnector from "../apiConnector";
import { CountryItem } from "../entities/CountryItem";
import { DivisionItem } from "../entities/DivisionItem";
import { EnumValues } from "../entities/EnumValues";

class AzureGraphDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor() {
    this.apiConnector = new ApiConnector("https://graph.microsoft.com/v1.0/");
  }

  // removed group, instead we use all users, group example in comment below
  public async getGroupMembers(): Promise<any> {
    return await this.apiConnector.get<any>(
      "users"     //https://graph.microsoft.com/v1.0/groups/edd306d0-080e-4c6b-b23d-6e0ce2840cee/members?$select=id,mail -- možnost listovat pouze některé elementy
    );
  }
  public async getDevTeam(): Promise<any> {
    return await this.apiConnector.get<any>(
      "groups/b22dc4b8-66ab-4222-a13e-fa677cdb9f6f/members"
    );
  }
  public async getUserManager(id: string): Promise<any> {
    return await this.apiConnector.get<any>(`users/${id}/manager`);
  }

  public async getMyPhoto(): Promise<any> {
    return await this.apiConnector.get<any>("me/photo/$value");
  }

  public async searchUserByMail(search: string): Promise<any> {
    return await this.apiConnector.get<any>(`users?$filter=startswith(mail,'${search}')`);
  }

  public async loadMyPhoto(): Promise<Blob> {
    return await this.apiConnector.getBlob("me/photo/$value");
  }

  public async loadPhotoById(id: string): Promise<any> {
    const blob = await this.apiConnector.getBlob(`users/${id}/photo/$value`)
    return { id: id, blob: blob };
  }
}

export default AzureGraphDataFetcher;
