<template>
    <DxDataGrid :hover-state-enabled="true" :show-borders="false" :data-source="props.data" id="gridContainer"
        :selected-row-keys="selectedKeys" :auto-navigate-to-focused-row="true" key-expr="id">
        <DxFilterRow :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxColumn data-field="id" data-type="string" caption="ID" :visible="false" />
        <DxStateStoring :enabled="true" type="localStorage" storage-key="storage" />
        <DxColumn data-field="name" data-type="string" caption="Name" />
        <DxColumn type="buttons" caption="Actions" :width="70">
            <DxButton hint="Edit rate" icon="edit" styling-mode="contained" type="normal" @click="edit" />
            <DxButton hint="Delete rate" icon="trash" styling-mode="contained" type="normal" @click="deleteRate" />
        </DxColumn>
        <DxPaging :page-size="12" />
        <DxPager :show-page-size-selector="true" :allowed-page-sizes="[5, 10, 20]" />
    </DxDataGrid>
</template>
  
<script setup lang="ts">
import { DivisionItem } from "@/services/entities/DivisionItem";
import DxDataGrid, {
    DxColumn,
    DxFilterRow,
    DxPaging,
    DxStateStoring,
    DxHeaderFilter, DxPager,
    DxButton,
} from "devextreme-vue/data-grid";
import { ref } from "vue";

const emit = defineEmits(["edit", "delete"]);
const selectedKeys = ref([] as number[]);

const props = defineProps({
    data: { Type: [] as DivisionItem[], default: [] as DivisionItem[] },
});

function edit(item: any) {
    selectedKeys.value = [item.row.data.id];
    emit("edit", item.row.data.id);
}

function deleteRate(item: any) {
    emit("delete", item.row.data.id);
}
</script>
  
<style scoped lang="scss">
.buttons {
    margin-bottom: 10px;

    b {
        margin-right: 3px;
    }
}
</style>
  