import { VacationItem } from "./Vacation";
import { UserRateItem } from "./UserRateItem";
import { OvertimeFondItem } from "./OvertimeFondItem";
import { DailyCommitmentItem } from "./dailyCommitment";

export interface UserItem {
  id: string;
  geid: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  managerGeid: string;
  costCenter: string;
  isAdmin: boolean;
  isReport: boolean;
  isDeleted: boolean;
  rates: UserRateItem[];
  dailyCommitments: DailyCommitmentItem[];
  currentRate: UserRateItem;
  employeeId: string | null;
  role: number;
  userRole: string;
  managerId: string;
  teamLeaderId: string;
  teamLeaderEmail: string;
  startDate: Date;

  flexiVacations: VacationItem[];
  floatingDays: VacationItem[];
  overtimeFonds: OvertimeFondItem[];
}

export enum UserRole {
  None = 0,
  Employee = 1,
  TeamLeader = 2,
  Administrator = 3,
  EmployeeReport = 11,
  TeamLeaderReport = 21,  
}
