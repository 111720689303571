<template>
  <TileCard id="monthly-history" :loaded="historyStore.getAllLoaded">
    <template #body>
      <span class="title-2">Monthly record history</span>
      <div v-for="(item) in historyStore.getAll" :key="item.actionDate" class="pes">
        <span>{{ formatTRUELocaleDateWithTime(new Date(item.actionDate)) }}</span>
        <span>{{ item.record }}</span>
        <span v-if="item.comment">: {{ item.comment }}</span>
      </div>
    </template>
  </TileCard>
</template>
  
<script setup lang="ts">
import TileCard from './util/TileCard.vue';
import { useReportingMonthStoreHistory } from "@/stores/reportingHistory"
import { onMounted } from 'vue';
import { formatLocaleDateWithTime, formatTRUELocaleDateWithTime } from "@/common/date"

const historyStore = useReportingMonthStoreHistory();

const props = defineProps({
  userId: { Type: {} as string, default: "" as string },
  year: { Type: {} as number },
  month: { Type: {} as number },
});

onMounted(async () => {
  await historyStore.loadHistory(props.year, props.month + 1, props.userId);
});
</script>
  
<style scoped lang="scss">
.pes {
  margin-top: 0.3rem;
}

.pes>span:nth-child(2n) {
  margin-left: 1rem;
}
</style>