import axios, { AxiosResponse, AxiosError, AxiosInstance } from "axios";
import { Authorization } from "./authenticate";

class ApiConnector {
  private readonly axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
    });
    this.axiosInstance.interceptors.request.use((config) => {
      config.headers.Token = `Bearer ${Authorization.token}`;
      config.headers.Authorization = `Bearer ${Authorization.token}`;
      return config;
    });0
  }

  private async request<T>(
    method: string,
    endpoint: string,
    data?: any
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance({
        method,
        url: endpoint,
        data,
      });
      return response.data;
    } catch (error: any) {
      throw (error as AxiosError).response?.data || error.message;
    }
  }
  private async requestBlob<T>(
    method: string,
    endpoint: string,
    data?: any,    
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance({
        method,
        url: endpoint,
        data,
        responseType: 'blob'
      });
      return response.data;
    } catch (error: any) {
      throw (error as AxiosError).response?.data || error.message;
    }
  }

  public async getBlob<T>(endpoint: string): Promise<T> {
    return await this.requestBlob<T>("GET", endpoint);
  }

  public async get<T>(endpoint: string): Promise<T> {
    return await this.request<T>("GET", endpoint);
  }

  public async put<T>(endpoint: string, data: any): Promise<T> {
    return await this.request<T>("PUT", endpoint, data);
  }

  public async post<T>(endpoint: string, data?: any): Promise<T> {
    return await this.request<T>("POST", endpoint, data);
  }

  public async delete<T>(endpoint: string): Promise<T> {
    return await this.request<T>("DELETE", endpoint);
  }
}

export default ApiConnector;
