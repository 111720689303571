<template>
  <div class="small-table" v-if="settingsStore.getHolidaysLoaded">
    <HolidayTable :data="settingsStore.getHolidays" @delete="deleteHoliday" @edit="showEditHoliday"></HolidayTable>
    <div class="buttons">
      <button class="accent" @click="showCreateHoliday"><b>+</b> Add holidays</button>
    </div>
  </div>
  <Loader v-else></Loader>
  <HolidayModal :modal-id="holidayModalId" :value="state.value" :isCreate="state.isCreate" @close="() => vfm.close(holidayModalId)"
    @create="create" @edit="edit">
  </HolidayModal>
</template>

<script setup lang="ts">
import HolidayTable from '@/components/tables/HolidayTable.vue';
import { useSettingsStore } from '@/stores/setting';
import { onMounted, reactive } from 'vue';
import HolidayModal from '@/components/modals/HolidayModal.vue';
import { useVfm } from 'vue-final-modal';
import { HolidayItem } from '@/services/entities/HolidayItem';
import Loader from '@/components/util/Loader.vue';

const settingsStore = useSettingsStore();
const vfm = useVfm();
const holidayModalId = Symbol("holidayModalId");

const state = reactive({
  value: {} as HolidayItem | [] as HolidayItem[],
  isCreate: false
});

onMounted(async () => {
  await settingsStore.loadHolidays();
});

async function create() {
  await settingsStore.createHolidays(state.value);
  vfm.close(holidayModalId)
  state.value = {} as HolidayItem;
}

async function edit() {
  await settingsStore.updateHoliday(state.value[0]);
  vfm.close(holidayModalId)
  state.value = {} as HolidayItem;
}

function showEditHoliday(holidayId: string) {
  state.isCreate = false;
  state.value = [settingsStore.getHolidays.find(a => a.id == holidayId)];
  vfm.open(holidayModalId);
}

function showCreateHoliday() {
  state.isCreate = true;
  state.value = [{ date: new Date() } as HolidayItem];
  vfm.open(holidayModalId);
}

async function deleteHoliday(holidayId: string) {
  await settingsStore.deleteHoliday(holidayId);
}

</script>

<style scoped lang="scss"></style>