import { defineStore } from "pinia";
import { getServerUrl } from "@/common/urls";
import SupportDataFetcher from "@/services/connectors/supportConnector";
import {
    closeToast,
    showLoading,
    showSuccess,
    showToastError,
  } from "@/toastification";
import { parseError } from "@/common/util";

const support = new SupportDataFetcher(getServerUrl());

export const useSupportStore = defineStore("support", {
    
    actions: {
        async assingMandatoryProjects(userId: string): Promise<void> {
            try {
                const toast = showLoading("Assinging mandatory projects to users");
                await support.assingUsers(userId);
                // TODO: show unauthorized
                closeToast(toast);
                showSuccess("Successfuly assigned");
            } catch (e: any) {
                console.log(e);
                showToastError(parseError(e));
            }
        },
    }
});