import ApiConnector from "../apiConnector";
import { LockHistoryItem } from "../entities/LockHistoryItem";

class LockDataFetcher {
    private readonly apiConnector: ApiConnector;

    constructor(baseURL: string) {
        this.apiConnector = new ApiConnector(baseURL);
    }

    public async getLockHistoryItems(year: number, month: number, userId: string) {
        return await this.apiConnector.get<any>(
            `lock/${year}/${month}/${userId}`
        );
    }

    public async saveLockHistoryItems(lockItems: LockHistoryItem[], userId: string, actionAuthor: string) {
        return await this.apiConnector.put<void>(`lock/intervals/${userId}/${actionAuthor}`, lockItems);
    }
}
export default LockDataFetcher;