
<template>
    <div>
        <div class="header">
            <span class="title">Service detail</span>
            <DxButton icon="trash" @click="emit('delete', props.service.id)" />
        </div>
        <div class="p-detail">
            <div>
                <span>Service Name</span>
                <span>{{ props.service.blServiceName }}</span>
            </div>
            <div>
                <span>BL PG Code</span>
                <span>{{ props.service.blpgCode }}</span>
            </div>
            <div>
                <span>Sales order</span>
                <span>{{ props.service.mxSalesOrder  }}</span>
            </div>
            <div>
                <span>Order value (USD)</span>
                <span>{{ props.service.orderValue }}</span>
            </div>
            <div>
                <span>Invoice address</span>
                <span>{{ props.service.invoiceAddress }}</span>
            </div>
            <div>
                <span>SAP number</span>
                <span>{{ props.service.sapNumber }}</span>
            </div>
            <div>
                <span>PO number</span>
                <span>{{ props.service.poNumber }}</span>
            </div>
            <div>
                <span>Operations manager</span>
                <span>{{ props.service.operationsManager }}</span>
            </div>
            <div>
                <span>Valid from</span>
                <span>{{ formatLocaleDate(props.service.validFrom) }}</span>
            </div>
            <div>
                <span>Valid to</span>
                <span>{{ formatLocaleDate(props.service.validTo) }}</span>
            </div>
            <div>
                <span>Division</span>
                <span>{{ settingsStore.getDivisions.find(a => a.id == props.service.divisionId)?.name }}</span>
            </div>
            <div>
                <span>Country</span>
                <span>{{ settingsStore.getCountries.find(a => a.id == props.service.countryId)?.name }}</span>
            </div>
            <div>
                <span>Link</span>
                <span>{{ props.service.link }}</span>
            </div>
            <div>
                <span>Description</span>
                <span>{{ props.service.description }}</span>
            </div>
        </div>
        <div class="buttons">
            <button @click="emit('edit', props.service.id)">Edit</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ServiceItem } from "@/services/entities/ServiceItem";
import { useSettingsStore } from "@/stores/setting";
import { formatLocaleDate } from "@/common/date";
import DxButton from 'devextreme-vue/button';

const settingsStore = useSettingsStore();
const emit = defineEmits(['edit', 'delete']);

const props = defineProps({
    service: { Type: {} as ServiceItem, default: {} as ServiceItem }
});
</script>

<style scoped lang="scss">
.title {
    font-size: 16px;
    margin-left: 0;
}

.header {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.p-detail {
    display: flex;
    flex-direction: column;

    &>div {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #BABABA;
        padding: 10px 0px 5px 0px;

        &:first-child {
            border-top: 1px solid #BABABA;
        }

        span {
            width: 50%;
        }
    }
}
</style>