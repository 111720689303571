import { defineStore } from "pinia";
import { PublicClientApplication } from "@azure/msal-browser";
import AzureGraphDataFetcher from "@/services/connectors/azureGraphConnector";

const graph = new AzureGraphDataFetcher();

export const useAzureStore = defineStore("azure", {
  state: () => ({
    groupMembers: [] as { value: any }[],
    userPhoto: {
      blob: {} as any,
      loader: true,
    },
    usersPhotos: [] as any[],
    userManager: {
      data: {} as any,
      loader: true,
    },
  }),
  actions: {
    async loadMyPhoto() {
      try {
        this.userPhoto.loader = true;
        const result = await graph.loadMyPhoto();
        if (result) {
          const blob = result;
          this.userPhoto.blob = blob;
          this.userPhoto.loader = false;
        } else {
          console.error("Failed to fetch photo");
        }
      } catch (error) {
        console.error("Error fetching photo:", error);
      }
    },
    async loadGroupMembers() {
      try {
        const members = await graph.getGroupMembers();
        const devteam = await graph.getDevTeam();
        this.groupMembers = members.value.concat(devteam.value);
      } catch (e) {
        console.error("Error loading group members", e);
      }
    },
    async loadUserPhotos(id: string | string[]) {
      try {
        this.userPhoto.loader = true;
        if (!Array.isArray(id)) {
          id = [id];
        }
        let promises = [];
        for (let i = 0; i < id.length; i++) {
          if(!id[i]) continue;
          promises.push(graph.loadPhotoById(id[i]));
        }
        const results = await Promise.all(promises);
        this.usersPhotos = results;
      } catch (e) {
        console.error("Error loading photo", e);
      }
    },
    async loadUserManager(id: string) {
      try {
        this.userManager.loader = true;
        const result = await graph.getUserManager(id);
        this.userManager.data = result;
      } catch (e) {
        console.error(e);
      } finally {
        this.userManager.loader = false;
      }
    },
    async searchUser(search: string) {
      try {
        const members = await graph.searchUserByMail(search);
        this.groupMembers = Object.values(members.value);
      } catch (e) {
        console.error("Error searching user", e);
      }
    },
  },
  getters: {
    getGroupMembers(): any[] {
      return this.groupMembers;
    },
    getPhoto(): any {
      return this.userPhoto.blob;
    },
    getUsersPhotos(): any[] {
      return this.usersPhotos;
    },
    getPhotoLoader(): boolean {
      return this.userPhoto.loader;
    },
    getUserManager(): any {
      return this.userManager.data;
    },
    getUserManagerLoader(): boolean {
      return this.userManager.loader;
    },
  },
});
