<template>
    <VueFinalModal class="modal" content-class="modal-content" overlay-transition="vfm-fade" :clickToClose="true">
        <UserProjectTable v-if="userStore.getUserProjectsLoaded" :projects="userStore.getUserProjects">
        </UserProjectTable>
        <Loader v-else></Loader>
    </VueFinalModal>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import { onMounted } from "vue";
import Loader from "@/components/util/Loader.vue";
import { VueFinalModal } from "vue-final-modal";
import UserProjectTable from "../tables/UserProjectTable.vue";

const props = defineProps(['userId']);

const userStore = useUserStore();

onMounted(async () => {
    await userStore.loadProjectsForUser(props.userId);
})

</script>

<style scoped lang="scss"></style>