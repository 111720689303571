import ReportingDataFetcher from "@/services/connectors/reportingConnector";
import { getServerUrl } from "@/common/urls";
import { defineStore } from "pinia";
import { ReportingHistory } from "@/services/entities/Reporting/ReportingHistory";

const reportingHistory = new ReportingDataFetcher(getServerUrl());

export const useReportingMonthStoreHistory = defineStore("history", {
    state: () => ({
        history: {
            data: [] as ReportingHistory[],
            loader: false,
        },
    }),
    actions: {
        async loadHistory(
            year: number,
            month: number,
            userId: string
        ): Promise<void> {
            try {
                this.history.data = [] as ReportingHistory[];
                this.history.loader = false;                
                this.history.data = await reportingHistory.getHistory(year, month, userId);
            } catch (e: any){
                console.error(e);
            } finally {
                this.history.loader = true;        
            }
        },
    },
    getters: {
        getAll(): ReportingHistory[] {
            return this.history.data;
        },
        getAllLoaded(): boolean {
            return this.history.loader;
        },
    },
});