import { ServiceItem } from "./ServiceItem";
import { DefinitionItem } from "./DefinitionItem";
import { UserItem } from "./UserItem";
import { MonthPicker, UserProjectItem } from "./UserProjectItem";

export interface ProjectItem {
  id: string;
  projectStart: Date;
  projectStartPicker: MonthPicker;
  projectEnd: Date;
  projectEndPicker: MonthPicker;
  projectTypeId: number;
  projectType: string;
  projectName: string;
  projectNumber: string;
  invoiceTypeId: number;
  responsiblePerson: string;
  projectManager: string;
  nonBillableProjectType: number;
  serviceId: number;
  service: ServiceItem | null;
  users: UserItem[];
  usersProject: UserProjectItem[];
  definitions: DefinitionItem[];
  description: string;
  getNonBillableProjectType: string;
  getProjectType: string;
  isDeleted: boolean;
  isCheckbox: boolean;
}

export enum ProjectType {
  Invoiced = 0,
  Internal = 1,
  NonBillable = 2,
  FloatingDays = 98,
  Vacation = 99,
}

export enum InvoiceType {
  Recovered = 0,
  CostCenter = 1,
}