<template>
  <VueFinalModal class="comment-modal" content-class="modal-content" overlay-transition="vfm-fade"
    content-transition="vfm-fade">
    <div class="tittle">
      <span>Comment</span>
    </div>
    <div>
      <textarea v-model="value.text" :disabled="disabled" />
    </div>
    <div class="comment-footer">
      <vue-select :appendToBody="true" label="name" :options="settingStore.getCountries" :clearable="false" :disabled="disabled"
        v-model="value.countryId" :reduce="(item) => item.id" />
      <button @click="emit('close')">Close</button>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { useSettingsStore } from "@/stores/setting";
import { computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import VueSelect from "vue-select";

const emit = defineEmits(['close', 'save', 'update:modelValue'])

const props = defineProps(['modelValue', 'disabled']);

const settingStore = useSettingsStore();

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<style scoped lang="scss">
textarea {
  width: calc(100% - 21px);
  min-height: 50px;
  resize: vertical;
}

.comment-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 0.5rem;
}
</style>