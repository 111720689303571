import { defineStore } from "pinia";
import OvervieDataFetcher from "@/services/connectors/overviewConnector";
import { EnumValues } from "@/services/entities/EnumValues";
import { getServerUrl } from "@/common/urls";

const baseURL = process.env.VUE_APP_API_BASE_URL;

if (!baseURL) {
  throw new Error("Base URL not defined in environment variables");
}
const overview = new OvervieDataFetcher(getServerUrl());

export const useOverviewStore = defineStore("overview", {
  state: () => ({
    nonBillableProjectType: {
      data: [] as EnumValues[],
      loader: false,
    },
    projectType: {
      data: [] as EnumValues[],
      loader: false,
    },
    invoiceType: {
      data: [] as EnumValues[],
      loader: false,
    },
  }),
  actions: {
    async loadNonBillableProjectType(): Promise<void> {
      try {
        this.nonBillableProjectType.data =
          await overview.getNonBillableProjectType();
      } catch (e: any) {
        console.log(e);
      }
    },
    async loadProjectTypes(): Promise<void> {
      try {
        this.projectType.data = await overview.getProjectTypes();
      } catch (e: any) {
        console.log(e);
      }
    },
    async loadInvoiceTypes(): Promise<void> {
      try {
        this.invoiceType.data = await overview.getInvoiceType();
      } catch (e: any) {
        console.log(e);
      }
    },
  },
  getters: {
    getProjectTypes(): EnumValues[] {
      return this.projectType.data;
    },
    getNonBillableProjectType(): EnumValues[] {
      return this.nonBillableProjectType.data;
    },
    getInvoiceType(): EnumValues[] {
      return this.invoiceType.data;
    },
  },
});
