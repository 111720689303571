import ApiConnector from "../apiConnector";
import { OvertimeModel } from "../entities/OvertimeModel";

class OvertimeDataFetcher {
    private readonly apiConnector: ApiConnector;

    constructor(baseURL: string){
        this.apiConnector = new ApiConnector(baseURL);
    }

    public async getOvertimeInfo(year: number, month: number, userId: string) {
        return await this.apiConnector.get<any>(
            `overtime/${year}/${month}/${userId}`
        );
    }
}

export default OvertimeDataFetcher