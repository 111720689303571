import ApiConnector from "../apiConnector";

class SupportDataFetcher {
    private readonly apiConnector: ApiConnector;

    constructor(baseURL: string){
        this.apiConnector = new ApiConnector(baseURL);
    }

    public async assingUsers(userId: string) {
        return await this.apiConnector.get<void>(`support/assignMandatory/${userId}`);
    }
}

export default SupportDataFetcher;