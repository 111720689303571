import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ReportHoursView from "../views/ReportHoursView.vue";
import ApprovalsView from "@/views/ApprovalsView.vue";
import ProjectsView from "@/views/ProjectsView.vue";
import ApplicationSettingsView from "@/views/ApplicationSettingsView.vue";
import ServicesView from "@/views/AppSettingNav/ServicesView.vue";
import AppApplicationSettingsView from "@/views/AppSettingNav/ApplicationSettingsView.vue";
import CountriesView from "@/views/AppSettingNav/CountriesView.vue";
import DivisionView from "@/views/AppSettingNav/DivisionView.vue";
import PublicHolidaysView from "@/views/AppSettingNav/PublicHolidaysView.vue";
import RatesView from "@/views/AppSettingNav/RatesView.vue";
import UserManagementViewVue from "@/views/UserManagementView.vue";
import LoginView from "@/views/Authorization/LoginView.vue";
import ReportingView from "@/views/ReportingView.vue";
import { Authorization, LogStatus } from "@/services/authenticate";
import { pages } from "@/common/pages";
import InvoicingView from "@/views/ReportingNav/InvoicingView.vue";
import R06View from "@/views/ReportingNav/R06View.vue";
import DailyReportView from "@/views/ReportingNav/DailyReportView.vue";
import SapReportView from "@/views/ReportingNav/SapReportView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ReportHours",
    component: ReportHoursView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/approvals",
    name: "Approvals",
    component: ApprovalsView,
  },
  {
    path: "/projects",
    name: "Projects",
    component: ProjectsView,
  },
  {
    path: "/users",
    name: "UserManagement",
    component: UserManagementViewVue,
  },
  {
    path: "/settings",
    name: "ApplicationSettings",
    component: ApplicationSettingsView,
    children: [
      {
        name: "AppSettings",
        path: "",
        component: AppApplicationSettingsView,
      },
      {
        name: "PublicHolidays",
        path: "/settings/holidays",
        component: PublicHolidaysView,
      },
      {
        name: "Countries",
        path: "/settings/countries",
        component: CountriesView,
      },
      {
        name: "Services",
        path: "/settings/services",
        component: ServicesView,
      },
      {
        name: "Divisions",
        path: "/settings/divisions",
        component: DivisionView,
      },
      {
        name: "Rates",
        path: "/settings/rates",
        component: RatesView,
      },
    ],
  },
  {
    path: "/reporting",
    name: "Reporting",
    component: ReportingView,
    children: [
      {
        name: "DailyReport",
        path: "",
        component: DailyReportView
      },
      {
        name: "YearlyReport",
        path: "/reporting/yearly",
        component: R06View
      },
      {
        name: "Invoicing",
        path: "/reporting/invoicing",
        component: InvoicingView,
      },
      {
        name: "SapReport",
        path: "/reporting/sap",
        component: SapReportView,
      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const role = sessionStorage.getItem("role")
  if (to.name !== "Login" && (!Authorization.isLogged || !role)) {
    sessionStorage.setItem("log_to", to.name?.toString() ?? "ReportHours");
    next({ name: "Login" });
  } 
  else if (to.name !== "Login" && !pages.find(a => a.role == (role ?? 0))?.pages.some(a => a.isActive(to))) {
    next({name: "ReportHours"});
  }
  else {
    next();
  }
});

export default router;
