<template>
  <div>
    <div class="header">
      <span class="title">Approvals</span>
      <div class="d-row">
        <VueDatePicker v-model="state.date" month-picker :clearable="false" @update:model-value="renewTable">
        </VueDatePicker>
        <span class="title">{{ reportingStore.getAll.userName }}</span>
      </div>
    </div>
    <div class="approval-body">
      <TileCard id="approval-table" class="user-table" :loaded="userStore.getForApprovalLoaded">
        <template #body>
          <UserTable @detail="loadReportTable" :data="userStore.getForApproval" @selection="selection" />
        </template>
        <template #footer>
          <div class="buttons">
            <button class="accent" @click="sendReminder" :disabled="state.selectedEmails.length == 0">Send
              reminder</button>
          </div>
        </template>
      </TileCard>
      <div class="user-detail" v-if="state.loadDetail">
        <TileCard id="report-table" :loaded="!reportingStore.getAllLoaded">
          <template #body>
            <ReportTable :reportingMonth="reportingStore.getAll" :daily-commitment="state.dailyCommitment">
              <template #buttons>
                <div v-if="checkForApproval">
                  {{ ApprovalStatus[reportingStore.getAll.status.id] }}
                  <!-- <button class="deccent" @click="approve">Approve</button>
                  <button class="accent" @click="reject">Reject</button> -->
                  <button class="accent" @click="vfm.open(approvalModalId)">
                    Approve / Reject
                  </button>
                </div>
                <button @click.once="save">Save</button>
                <button @click="() => vfm.open(lockModalId)">Edit locks</button>
              </template>
            </ReportTable>
          </template>
        </TileCard>
        <div class="d-row approval-detail" v-if="!reportingStore.getAllLoaded">
          <OvertimeOverview :overtimeModel="overtimeStore.getModel" :loaded="overtimeStore.getModelLoaded" />
          <MonthlyHistory :year="state.date.year" :month="state.date.month" :userId="reportingStore.getAll.userId" />
          <LockModal :locks="reportingStore.getAll.lockHistoryItems" :userName="reportingStore.getAll.userName"
            :userId="reportingStore.getAll.userId" :year="state.date.year" :month="state.date.month + 1"
            :modal-id="lockModalId" @close="() => vfm.close(lockModalId)" @refreshUsers="refreshUsers" />
          <ApprovalModal :modal-id="approvalModalId" @approve="approve" @reject="reject"></ApprovalModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import UserTable from "@/components/tables/UserTable.vue";
import ReportTable from "@/components/tables/ReportTable.vue";
import OvertimeOverview from "@/components/OvertimeOverview.vue";
import MonthlyHistory from "@/components/MonthlyHistory.vue";
import { onBeforeMount, onMounted, onUnmounted, reactive } from "vue";
import { useUserStore } from "@/stores/user";
import TileCard from "@/components/util/TileCard.vue";
import { useReportingMonthStore } from "@/stores/reporting";
import { Authorization } from "@/services/authenticate";
import { useVfm } from "vue-final-modal";
import LockModal from "@/components/modals/LockModal.vue";
import {
  MonthlyApprovalItem,
  ApprovalStatus,
} from "@/services/entities/Approval/MonthlyApprovalItem";
import { useApprovalStore } from "@/stores/approval";
import { useOvertimeStore } from "@/stores/overtime";
import ApprovalModal from "@/components/modals/ApprovalModal.vue";
import { showSuccess, showToastError } from "@/toastification";

const vfm = useVfm();
const lockModalId = Symbol("lockModalId");
const approvalModalId = Symbol("approvalModalId");

const state = reactive({
  date: { year: new Date().getFullYear(), month: new Date().getMonth() },
  loadDetail: false,
  selectedEmails: [] as string[],
  dailyCommitment: 0,
});

const userStore = useUserStore();
const reportingStore = useReportingMonthStore();
const approvalStore = useApprovalStore();
const overtimeStore = useOvertimeStore();

onUnmounted(() => {
  reportingStore.clearReportingMonth();
});

async function sendReminder() {
  console.log("Send reminder", state.selectedEmails);

  const confirmed = confirm(`Are you sure you want to send a reminder to ${state.selectedEmails.length} selected ${state.selectedEmails.length == 1 ? "person" : "people"}?`);
  if (confirmed) {
    const url = "https://prod-00.westeurope.logic.azure.com:443/workflows/dd227ea9900041eaae9b822de2517584/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=KK5a8ljyZBN_umq9cta_toYb2YjvdyzA_upCfNyhjOs";
    const body = {
      selectedEmails: state.selectedEmails
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        console.log("Reminder sent successfully");
        showSuccess("Reminder sent successfully");
      } else {
        console.error("Failed to send reminder");
        showToastError("Failed to send reminder");
      }
    } catch (error) {
      console.error("An error occurred while sending the reminder:", error);
      showToastError("An error occurred while sending the reminder");
    }
  }
}

function selection(selectedData: any[]) {
  state.selectedEmails = selectedData.map(a => a.userItem.email);
}

async function loadReportTable(user: string) {
  state.loadDetail = true;
  await reportingStore.loadReportingMonth(
    state.date.year,
    state.date.month + 1,
    user
  );

  state.dailyCommitment = userStore.getForApproval.approvalUsers.find(a => a.userItem.id == user)?.userItem?.dailyCommitments
                            .filter(a => a.year <= state.date.year && a.month <= state.date.month)
                            .sort((a, b) => b.year - a.year).sort((a, b) => b.month - a.month)[0]?.hours ?? 0;

  await overtimeStore.loadOvertimeModel(
    state.date.year,
    state.date.month + 1,
    user
  );
}
async function renewTable() {
  await userStore.loadApproval(
    state.date.year,
    state.date.month + 1,
    Authorization.id
  );
  await loadReportTable(reportingStore.getAll.userId);
}

async function refreshUsers() {
  vfm.close(lockModalId);
  await userStore.loadApproval(
    state.date.year,
    state.date.month + 1,
    Authorization.id
  );
}

async function createMonthlyApprovalItem(status: ApprovalStatus, text: string) {
  return {
    year: state.date.year,
    month: state.date.month + 1,
    comment: text,
    userId: reportingStore.getAll.userId,
    status,
  } as MonthlyApprovalItem;
}

async function saveAndLoadApproval(status: ApprovalStatus, text: string) {
  const mA = await createMonthlyApprovalItem(status, text);
  await approvalStore.saveApproval(mA, Authorization.id);
  const tasks = [
    userStore.loadApproval(
      state.date.year,
      state.date.month + 1,
      Authorization.id
    ),
    reportingStore.loadReportingMonth(mA.year, mA.month, mA.userId),
    overtimeStore.loadOvertimeModel(
      state.date.year,
      state.date.month + 1,
      mA.userId
    ),
  ];
  await Promise.all(tasks);
}

async function approve(text: string) {
  await saveAndLoadApproval(ApprovalStatus.Approved, text);
}

async function reject(text: string) {
  await saveAndLoadApproval(ApprovalStatus.Rejected, text);
}

function checkForApproval() {
  return (
    (reportingStore.getAll.status.id as ApprovalStatus) ==
    ApprovalStatus.SentForApproval ||
    (reportingStore.getAll.status.id as ApprovalStatus) ==
    ApprovalStatus.Rejected
  );
}

async function save() {
  // Check if there are any lines without ActivityNumberId
  if (
    reportingStore.getAll.lines.some(
      (a) => (a.projectTypeId == 0 || a.projectTypeId == 1) && !a.activity
    )
  ) {
    showToastError("Please select activity for all projects");
    return;
  }
  // Group by project and activity, then count the number of lines, if more than 1, show error
  let grouped = reportingStore.getAll.lines.reduce((a, b) => {
    let key = `${b.project?.projectId}-${b.activity?.id}`;
    if (!a[key]) {
      a[key] = 0;
    }
    a[key]++;
    return a;
  }, {} as { [key: string]: number });
  let errors = Object.keys(grouped).filter((a) => grouped[a] > 1);
  if (errors.length > 0) {
    showToastError(
      "There are multiple lines for the same project and activity"
    );
    return;
  }
  if (
    reportingStore.getAll.lines.some(
      (a) => a.projectTypeId > 90 || a.projectTypeId == 2
    )
  ) {
    reportingStore.getAll.lines.forEach((a) => {
      a.projectId = a.project?.projectId;
      if (a.activity) {
        a.activityNumberId = a.activity?.id;
      }
      if (a.projectTypeId > 90 || a.projectTypeId == 2) {
        a.activityTypeId = 0;
        a.activityNumberId =
          reportingStore.getAll.projects.find((b) => b.projectId == a.projectId)
            ?.activityNumbers[0]?.id ?? "";
      }
      a.days.forEach((b) => {
        if (typeof b.hours == "string") {
          b.hours = null;
        }
      });
    });
  }

  reportingStore.getAll.actionAuthor = Authorization.id;
  reportingStore.getAll.lines = reportingStore.getAll.lines.filter(
    (a) => a.project
  );

  await reportingStore.saveMonth(reportingStore.getAll);

  await overtimeStore.loadOvertimeModel(
    state.date.year,
    state.date.month + 1,
    reportingStore.getAll.userId
  );
  await userStore.loadApproval(
    state.date.year,
    state.date.month + 1,
    Authorization.id
  );
}

onBeforeMount(async () => {
  //await userStore.loadForManager(Authorization.id);
  await userStore.loadApproval(
    state.date.year,
    state.date.month + 1,
    Authorization.id
  );
});
onUnmounted(() => {
  reportingStore.clearReportingMonth();
});
</script>

<style scoped lang="scss">
.approval-body {
  display: flex;
  max-height: 75vh;
  width: 100%;
  flex-direction: row;

  #approval-table {
    overflow: auto;
  }
}

.approval-detail {
  &>div {
    max-height: 15rem;
    overflow: auto;
  }
}

.user-table {
  width: 20%;
  max-width: 492px;
  min-height: 377px;
}

.user-detail {
  overflow: auto;
  max-height: 75vh;
}
</style>
