import ApiConnector from "../apiConnector";
import { CountryItem } from "../entities/CountryItem";
import { DivisionItem } from "../entities/DivisionItem";
import { HolidayItem } from "../entities/HolidayItem";
import { RateItem } from "../entities/RateItem";
import { ServiceItem } from "../entities/ServiceItem";
import { ActivityTypeItem } from "../entities/ActivityType";

class SettingsDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor(baseURL: string) {
    this.apiConnector = new ApiConnector(baseURL);
  }

  public async getCountries(): Promise<CountryItem[]> {
    return await this.apiConnector.get<CountryItem[]>("settings/countries");
  }

  public async createDivision(division: DivisionItem): Promise<void> {
    return await this.apiConnector.post<void>("settings/divisions", division);
  }

  public async getDivisions(): Promise<DivisionItem[]> {
    return await this.apiConnector.get<DivisionItem[]>("settings/divisions");
  }

  public async updateDivision(division: DivisionItem): Promise<void> {
    return await this.apiConnector.put<void>("settings/divisions", division);
  }

  public async deleteDivision(id: number): Promise<number> {
    return await this.apiConnector.delete<number>("settings/divisions/" + id);
  }

  public async createRate(rate: RateItem): Promise<void> {
    return await this.apiConnector.post<void>("settings/rates", rate);
  }

  public async getRates(): Promise<RateItem[]> {
    return await this.apiConnector.get<RateItem[]>("settings/rates");
  }

  public async updateRate(rate: RateItem): Promise<void> {
    return await this.apiConnector.put<void>("settings/rates", rate);
  }

  public async deleteRate(rateId: string): Promise<number> {
    return await this.apiConnector.delete<number>("settings/rates/" + rateId);
  }

  public async createService(service: ServiceItem) {
    return await this.apiConnector.post<void>("settings/service", service);
  }

  public async getServices(): Promise<any[]> {
    return await this.apiConnector.get<any[]>("settings/service");
  }

  public async updateService(service: ServiceItem) {
    return await this.apiConnector.put<string>("settings/service", service);
  }

  public async deleteService(serviceId: string) {
    return await this.apiConnector.delete<any>("settings/service/" + serviceId);
  }

  public async createHolidays(holidays: HolidayItem[]): Promise<void> {
    return await this.apiConnector.post<void>("settings/holiday", holidays);
  }

  public async getHolidays(): Promise<HolidayItem[]> {
    return await this.apiConnector.get<HolidayItem[]>("settings/holiday");
  }

  public async updateHoliday(holiday: HolidayItem) {
    return await this.apiConnector.put<string>("settings/holiday", holiday);
  }

  public async deleteHoliday(holidayId: string) {
    return await this.apiConnector.delete<any>("settings/holiday/" + holidayId);
  }

  public async createActivityType(activity: ActivityTypeItem): Promise<void> {
    return await this.apiConnector.post<void>("settings/activity", activity);
  }

  public async getActivityTypes(): Promise<ActivityTypeItem[]> {
    return await this.apiConnector.get<ActivityTypeItem[]>("settings/activity");
  }

  public async updateActivtyType(activity: ActivityTypeItem) {
    return await this.apiConnector.put<string>("settings/activity", activity);
  }

  public async deleteActivityType(id: number) {
    return await this.apiConnector.delete<any>("settings/activity/" + id);
  }
}

export default SettingsDataFetcher;
