import { defineStore } from "pinia";
import { CountryItem } from "@/services/entities/CountryItem";
import { DivisionItem } from "@/services/entities/DivisionItem";
import SettingsDataFetcher from "@/services/connectors/settingsConnector";
import { RateItem } from "@/services/entities/RateItem";
import { ServiceItem } from "@/services/entities/ServiceItem";
import { getServerUrl } from "@/common/urls";
import { HolidayItem } from "@/services/entities/HolidayItem";
import { closeToast, showLoading, showSuccess, showToastError } from "@/toastification";
import { ActivityTypeItem } from "@/services/entities/ActivityType";
import { parseError } from "@/common/util";

const settings = new SettingsDataFetcher(getServerUrl());

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    countries: {
      data: [] as CountryItem[],
      loader: false,
    },
    divisions: {
      data: [] as DivisionItem[],
      loader: false,
    },
    rates: {
      data: [] as RateItem[],
      loader: false,
    },
    services: {
      data: [] as ServiceItem[],
      loader: false,
    },
    holidays: {
      data: [] as HolidayItem[],
      loader: false,
    },
    activityTypes: {
      data: [] as ActivityTypeItem[],
      loader: false
    }
  }),
  actions: {
    async loadCountries(): Promise<void> {
      try {
        this.countries.data = await settings.getCountries();
      } catch (e: any) {
        console.log(e);
      }
    },
    async loadDivisions(): Promise<void> {
      try {
        this.divisions.data = await settings.getDivisions();
      } catch (e: any) {
        console.log(e);
      }
    },
    async createDivision(item: DivisionItem): Promise<void> {
      try {
        const toast = showLoading("Creating new Division"); 
        await settings.createDivision(item);
        closeToast(toast);
        showSuccess("Division created");
      } catch (e: any) {
        showToastError("Failed to create division: " + parseError(e));
        console.log(e);
      }
    },
    async updatingDivision(item: DivisionItem): Promise<void> {
      try {
        const toast = showLoading("Updating Division"); 
        await settings.updateDivision(item);
        closeToast(toast);
        showSuccess("Division updated");
      } catch (e: any) {
        showToastError("Failed to update division: " + parseError(e));
        console.log(e);
      }
    },
    async deleteDivision(id: number): Promise<void> {
      try {
        const toast = showLoading("Deleting Division"); 
        await settings.deleteDivision(id);
        closeToast(toast);
        showSuccess("Division deleted");
      } catch (e: any) {
        showToastError("Failed to delete division: " + parseError(e));
        console.log(e);
      }
    },
    async loadRates(): Promise<void> {
      try {
        this.rates.data = await settings.getRates();
      } catch (e: any) {
        console.log(e);
      }
    },
    async createRate(rate: RateItem): Promise<void> {
      try {
        const toast = showLoading("Creating new rate"); 
        await settings.createRate(rate);
        closeToast(toast);
        showSuccess("Rate created");
        await this.loadRates();
      } catch (e: any) {
        showToastError("Failed to create rate: " + parseError(e));
        console.log(e);
      }
    },
    async updateRate(rate: RateItem): Promise<void> {
      try {
        const toast = showLoading("Updating rate"); 
        await settings.updateRate(rate);
        closeToast(toast);
        showSuccess("Rate updated");
        await this.loadRates();
      } catch (e: any) {
        showToastError("Failed to update rate: " + parseError(e));
        console.log(e);
      }
    },
    async deleteRate(rateId: string): Promise<void> {
      try {
        const toast = showLoading("Deleting rate"); 
        await settings.deleteRate(rateId);
        closeToast(toast);
        showSuccess("Rate deleted");
        await this.loadRates();
      } catch (e: any) {
        showToastError("Failed to delete rate: " + parseError(e));
        console.log(e);
      }
    },
    async loadServices(): Promise<void> {
      try {
        this.services.loader = true;
        const result = await settings.getServices();        
        result.forEach(element => {
          element.country = this.countries.data.find(a => a.id == element.countryId);
        });
        this.services.data = result;
      } catch (e: any) {
        console.log(e);
        showToastError("Failed to load services: " + parseError(e));
      } finally {
        this.services.loader = false;
      }
    },
    async createService(service: ServiceItem): Promise<void> {
      try {
        const toast = showLoading("Creating service"); 
        await settings.createService(service);
        closeToast(toast);
        showSuccess("Service created");
        await this.loadServices();
      } catch (e: any) {
        showToastError("Failed to create service: " + parseError(e));
        console.log(e);
      }
    },
    async updateService(service: ServiceItem): Promise<void> {
      try {
        const toast = showLoading("Updating service"); 
        await settings.updateService(service);
        closeToast(toast);
        showSuccess("Service updated");
        await this.loadServices();
      } catch (e: any) {
        showToastError("Failed to update service: " + parseError(e));
        console.log(e);
      }
    },
    async deleteService(serviceId: string): Promise<void> {
      try {
        const toast = showLoading("Deleting service"); 
        await settings.deleteService(serviceId);
        closeToast(toast);
        showSuccess("Service deleted");
        await this.loadServices();
      } catch (e: any) {
        showToastError("Failed to delete service: " + parseError(e));
        console.log(e);
      }
    },
    async loadHolidays(): Promise<void> {
      try {
        this.holidays.loader = true;
        this.holidays.data = await settings.getHolidays();
      } catch (e: any) {
        console.log(e);
      } finally {
        this.holidays.loader = false;
      }
    },
    async createHolidays(holidays: HolidayItem[]): Promise<void> {
      try {
        const toast = showLoading("Creating holidays"); 
        await settings.createHolidays(holidays);
        closeToast(toast);
        showSuccess("Holidays created");
        await this.loadHolidays();
      } catch (e: any) {
        showToastError("Failed to create holidays: " + parseError(e));
        console.log(e);
      }
    },
    async updateHoliday(holiday: HolidayItem): Promise<void> {
      try {
        const toast = showLoading("Updating holiday"); 
        await settings.updateHoliday(holiday);
        closeToast(toast);
        showSuccess("Holidays updated");
        await this.loadHolidays();
      } catch (e: any) {
        showToastError("Failed to update holidays: " + parseError(e));
        console.log(e);
      }
    },
    async deleteHoliday(holidayId: string): Promise<void> {
      try {
        const toast = showLoading("Deleting holiday"); 
        await settings.deleteHoliday(holidayId);
        closeToast(toast);
        showSuccess("Holidays deleted");
        await this.loadHolidays();
      } catch (e: any) {
        showToastError("Failed to delete holidays: " + parseError(e));
        console.log(e);
      }
    },
    async loadActivityTypes(): Promise<void> {
      try {
        this.activityTypes.loader = true;
        this.activityTypes.data = await settings.getActivityTypes();
      } catch (e: any) {
        console.log(e);
      } finally {
        this.holidays.loader = false;
      }
    },
    async createActivityType(activity: ActivityTypeItem): Promise<void> {
      try {
        const toast = showLoading("Creating activity tyúe"); 
        await settings.createActivityType(activity);
        closeToast(toast);
        showSuccess("Activity type created");
        await this.loadHolidays();
      } catch (e: any) {
        showToastError("Failed to create activity type: " + parseError(e));
        console.log(e);
      }
    },
    async updateActivity(activity: ActivityTypeItem): Promise<void> {
      try {
        const toast = showLoading("Updating activity type"); 
        await settings.updateActivtyType(activity);
        closeToast(toast);
        showSuccess("Activity type updated");
        await this.loadActivityTypes();
      } catch (e: any) {
        showToastError("Failed to update activity type: " + parseError(e));
        console.log(e);
      }
    },
    async deleteActivityType(id: number): Promise<void> {
      try {
        const toast = showLoading("Deleting activity type"); 
        await settings.deleteActivityType(id);
        closeToast(toast);
        showSuccess("Activity type deleted");
        await this.loadActivityTypes();
      } catch (e: any) {
        showToastError("Failed to delete activity type: " + parseError(e));
        console.log(e);
      }
    },
  },
  getters: {
    getCountries(): CountryItem[] {
      return this.countries.data;
    },
    getDivisions(): DivisionItem[] {
      return this.divisions.data;
    },
    getDivisionsLoaded(): boolean {
      return !this.divisions.loader;
    },
    getRates(): RateItem[] {
      return this.rates.data;
    },
    getRatesLoaded(): boolean {
      return !this.rates.loader;
    },
    getServices(): ServiceItem[] {
      return this.services.data;
    },
    getServicesLoaded(): boolean {
      return !this.services.loader;
    },
    getHolidays(): HolidayItem[] {
      return this.holidays.data;
    },
    getHolidaysLoaded(): boolean {
      return !this.holidays.loader;
    },
    getActivityTypes(): ActivityTypeItem[] {
      return this.activityTypes.data;
    },
    getActivityTypesLoaded(): boolean {
      return this.activityTypes.loader;
    },
  },
});
