<template>
    <div class="container">
        <h4>Get report for selected month {{ monthlyReportText }}</h4>
        <div class="d-row-3">
            <div>
                <label>Month</label>
                <VueDatePicker v-model="state.dateMonth" month-picker :clearable="false">
                </VueDatePicker>
            </div>
            <div class="d-checkbox">
                <label for="weekly">Weekly</label>
                <input type="checkbox" name="weekly" id="weekly" v-model="state.monthlyOptions.weekly">
                <label for="daily">Daily</label>
                <input type="checkbox" name="daily" id="daily" v-model="state.monthlyOptions.daily">
            </div>
            <div class="buttons">
                <button class="accent" @click="getMonthlyReport">Get report</button>
            </div>
        </div>
    </div>
    <LoadingDownloandScreen v-if="state.showLoading"></LoadingDownloandScreen>
</template>

<script setup lang="ts">
import LoadingDownloandScreen from "@/components/util/LoadingDownloadScreen.vue";
import { computed, reactive } from "vue";
import { useReportStore } from "@/stores/report";
import { showToastError } from "@/toastification";
import { parseError } from "@/common/util";

const state = reactive({
    dateMonth: { year: (new Date).getFullYear(), month: (new Date).getMonth() },
    monthlyOptions: { weekly: true, daily: true },
    showLoading: false,
});

const reportStore = useReportStore();

const monthlyReportText = computed(() => {
    let result = "";
    if (state.monthlyOptions.weekly || state.monthlyOptions.daily) {
        result = "with ";
        if (state.monthlyOptions.weekly && state.monthlyOptions.daily) {
            result += "weekly and daily ";
        }
        else if (state.monthlyOptions.weekly) {
            result += "weekly ";
        }
        else if (state.monthlyOptions.daily) {
            result += "daily ";
        }
    }
    return result;
});

async function getMonthlyReport() {
    state.showLoading = true;
    try {
        await reportStore.downloadMonthly(state.dateMonth.year, state.dateMonth.month + 1,
            state.monthlyOptions.weekly, state.monthlyOptions.daily);
    }
    catch (e) {
        console.error(e);
        showToastError("Report was unable to generate: " + parseError(e));
    }
    finally {
        state.showLoading = false;
    }
}

</script>

<style scoped lang="scss">
.d-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>