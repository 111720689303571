<template>
    <DxDataGrid :hover-state-enabled="true" :show-borders="false" :data-source="props.data" id="holidayGridContainer"
        :selected-row-keys="selectedKeys" :auto-navigate-to-focused-row="true" key-expr="id">
        <DxFilterRow :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxColumn data-field="id" data-type="string" caption="ID" :visible="false" />
        <DxStateStoring :enabled="true" type="localStorage" storage-key="holidayGridContainer" />
        <DxColumn data-field="date" data-type="date" caption="Date" />
        <DxColumn data-field="name" data-type="string" caption="Name" />
        <DxColumn data-field="description" data-type="string" caption="Description" />
        <DxColumn type="buttons" caption="Actions" :width="70">
            <!-- <DxButton hint="Hide detail" icon="eyeclose" styling-mode="contained" type="normal" @click="clearDetail" /> -->
            <DxButton hint="Edit holiday" icon="edit" styling-mode="contained" type="normal" @click="editHoliday" />
            <DxButton hint="Delete holiday" icon="trash" styling-mode="contained" type="normal" @click="deleteHoliday" />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager :show-page-size-selector="true" :allowed-page-sizes="[5, 10, 20]" />
    </DxDataGrid>
</template>

<script setup lang="ts">
import { HolidayItem } from "@/services/entities/HolidayItem";
import DxDataGrid, {
    DxColumn,
    DxFilterRow, DxPager,
    DxPaging,
    DxHeaderFilter,
    DxStateStoring,
    DxButton,
} from "devextreme-vue/data-grid";
import { ref } from "vue";

const emit = defineEmits(['delete', 'edit'])
const selectedKeys = ref([] as number[]);

const props = defineProps({
    data: { Type: [] as HolidayItem[], default: [] as HolidayItem[] },
});

function editHoliday(item: any) {
    selectedKeys.value = [item.row.data.id];
    emit('edit', item.row.data.id)
}
function deleteHoliday(item: any) {
    emit('delete', item.row.data.id)
}
</script>

<style scoped lang="scss"></style>