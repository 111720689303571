import { isDevelopment } from "@/common/env";
import { PublicClientApplication } from "@azure/msal-browser";

const baseURL = process.env.BASE_URL;

if (!baseURL) {
  throw new Error("Base URL not defined in environment variables");
}

const msalConfig = {
  auth: {
    clientId: "c8418491-7526-448e-8cab-aa635fceec48",
    authority:
      "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
  system: {
    tokenRenewalOffsetSeconds: 600,
    iframeHashTimeout: 30000, // iframe is used to refresh the refresh_token as it has a 24h lifetime
  },
};

export enum LogStatus {
  None,
  NotAuthorized,
  NoAccess,
  Logged,
}

export class Authorization {
  private static _token: string = "";
  private static _name: string = "Unknown user";
  private static _logStatus: LogStatus = LogStatus.None;
  private static _id: string = "";

  static get token(): string {
    return this._token;
  }

  static set token(value: string) {
    this._token = value;
  }

  static get accountName(): string {
    return this._name;
  }

  static get id(): string {
    return this._id;
  }

  static get logStatus(): LogStatus {
    return this._logStatus;
  }

  static get isLogged(): boolean {
    return this._logStatus == LogStatus.Logged;
  }

  private static msalInstance = new PublicClientApplication(msalConfig);

  static async signIn() {
    if (!isDevelopment) {
      await this.getToken();
    } else {
      this.token = process.env.VUE_APP_ACCESS ?? "";
      this._id = process.env.VUE_APP_USER_ID ?? "";      
      this._logStatus = LogStatus.Logged;
    }
  }

  static async getToken() {
    console.log("initialize");
    await this.msalInstance.initialize();
    console.log("handleRedirectPromise");
    await this.msalInstance.handleRedirectPromise();
    const accounts = this.msalInstance.getAllAccounts();
    const silentRequest = {
      scopes: [".default"], // required scope
      account: accounts[0],
    };
    try {
      console.log("acquireTokenSilent");
      await this.msalInstance
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          this.token = response.accessToken;
          this._logStatus = LogStatus.Logged;
          this._name = response.account?.name ?? "";
          this._id = response.account?.localAccountId ?? "";
        });
    } catch (e) {
      console.error(e);
      try {
        console.log("loginRedirect");
        await this.msalInstance.loginRedirect();
      } catch (e) {
        console.error(e);
        this._logStatus = LogStatus.NotAuthorized;
      }
    }
  }
}
