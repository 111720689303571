<template>
    <div>
        <div class="header">
            <span class="title">User management</span>
        </div>
        <div class="d-row">
            <TileCard id="usr-mng" :loaded="userStore.getAllLoaded">
                <template #body>
                    <UserManagementTable :data="userStore.getAll" @detail="(a) => showDetail(a)"></UserManagementTable>
                    <div class="buttons">
                        <button class="accent" @click="() => state.userDetail = { startDate: new Date() }"><b>+</b> Add
                            user</button>
                    </div>
                </template>
            </TileCard>
            <TileCard id="usr-detail" class="usr-detail" v-if="state.userDetail" :loaded="userStore.getAllLoaded">
                <template #body>
                    <UserDetail :user="state.userDetail" @create="createUser" @update="updateUser"
                        @delete-user-rate="deleteUserRate"></UserDetail>
                </template>
            </TileCard>
        </div>
    </div>
</template>

<script setup lang="ts">
import TileCard from '@/components/util/TileCard.vue';
import { useUserStore } from '@/stores/user';
import { onMounted, reactive } from 'vue';
import UserManagementTable from '@/components/tables/UserManagementTable.vue';
import UserDetail from '@/components/user/UserDetail.vue';
import { UserItem } from '@/services/entities/UserItem';
import { useAzureStore } from '@/stores/azure';

const userStore = useUserStore();
const azureStore = useAzureStore();

const state = reactive({
    userDetail: null,
});

onMounted(async () => {
    await userStore.loadAll();
    await azureStore.loadGroupMembers();
    await userStore.loadTeamLeaders()
});

async function createUser(user: UserItem) {
    await userStore.create(user)
    showDetail(user.id);
}
async function updateUser(user: UserItem) {
    await userStore.update(user)
    showDetail(user.id);
}
async function deleteUserRate(id: string) {
    await userStore.deleteUserRate(id);
    showDetail(state.userDetail?.id);
}

function showDetail(id: string) {
    state.userDetail = null;
    setTimeout(() => { state.userDetail = userStore.getAll.find(a => a.id == id); }, 100);
}

</script>

<style scoped lang="scss">
#usr-mng {
    overflow: auto;
    max-height: calc(100vh - 200px);
    ;
}

.usr-detail {
    min-width: 420px;
}
</style>