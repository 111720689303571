<template>
    <VueFinalModal class="modal" content-class="modal-content" overlay-transition="vfm-fade" :clickToClose="false"
        @click-outside="beforeClose">
        <div>
            <span class="title" v-if="props.value.id">Edit Rate</span>
            <span class="title" v-else>Create Rate</span>
            <div class="d-row">
                <div>
                    <label for="r_name">Name</label>
                    <input id="r_name" type="string" v-model="props.value.name" />
                </div>
                <div>
                    <label for="r_value">Value</label>
                    <input id="r_value" type="number" v-model="props.value.value" />
                </div>
                <div>
                    <label for="i_description">Description</label>
                    <textarea id="i_description" v-model="props.value.description" />
                </div>
                <div>
                    <label for="r_currency">Currency</label>
                    <input id="r_currency" type="string" v-model="props.value.currency" />
                </div>
            </div>
        </div>
        <div class="buttons">
            <button @click="beforeClose">Cancel</button>
            <button class="accent" @click="edit" v-if="props.value.id">Save changes</button>
            <button class="accent" @click="create" v-else>Create rate</button>
        </div>
        <ConfirmModal :modal-id="confirmModalId" text="You have unsaved changes, do you really want to leave this form?"
            confirm-text="Close form" @confirm="() => {
                vfm.close(confirmModalId);
                emit('close');
            }
                " @close="vfm.close(confirmModalId)">
        </ConfirmModal>
    </VueFinalModal>
</template>

<script setup lang="ts">
import { RateItem } from "@/services/entities/RateItem";
import { useVfm, VueFinalModal } from "vue-final-modal";
import ConfirmModal from "./ConfirmModal.vue";

const vfm = useVfm();
const confirmModalId = Symbol("confirmModalId");
const emit = defineEmits(["create", "close", "edit"]);

function beforeClose() {
    vfm.open(confirmModalId);
}

const props = defineProps({
    value: { Type: {} as RateItem, default: {} as RateItem },
});

function create() {
    emit("create");
}

function edit() {
    emit("edit");
}
</script>

<style scoped lang="scss">
.title {
    font-size: 16px;
}
</style>