import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSelect from "vue-select";
import { createVfm } from "vue-final-modal";
import VueDatePicker from "@vuepic/vue-datepicker";
import { createPinia } from "pinia";
import Toast from "vue-toastification";
import { Authorization } from "./services/authenticate";

import "vue-final-modal/style.css";
import "devextreme/dist/css/dx.light.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue-toastification/dist/index.css";

const app = createApp(App);
const vfm = createVfm();
const pinia = createPinia();

app.config.globalProperties.$msalInstance = {};

app.component("VueDatePicker", VueDatePicker);
app.component("v-select", VueSelect);
app.use(vfm);
app.use(pinia);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "bottom-right",
});
app.use(router);
app.mount("#app");
