export function formatISODate(date: string): string {
  return date.slice(0, 10);
}

export function formatLocaleDate(date: Date | string): string {
  if (typeof date === "string") {
    date = new Date(date.toLocaleString());
  }
  const local = date
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/");
  return `${local[2]}/${local[1]}/${local[0]}`;
}

export function formatLocaleDateWithTime(date: Date): string {
  const local = date
    .toLocaleDateString("en-GB", {
      minute: "2-digit",
      hour: "2-digit",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .split(",");
  const localDate = local[0].split("/");
  return `${localDate[2]}/${localDate[1]}/${localDate[0]}, ${local[1]}`;
}

export function formatDate(date: string | Date) {
  if (date) {
    return formatLocaleDateWithTime(
      date instanceof Date ? date : new Date(date)
    );
  }
}

export function formatTRUELocaleDateWithTime(date: Date): string {
  const local = date
    .toLocaleDateString("es-MX", {
      minute: "2-digit",
      hour: "2-digit",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .split(",");
  const localDate = local[0].split("/");
  return `${localDate[2]}/${localDate[1]}/${localDate[0]}, ${local[1]}`;
}