<template>
  <div class="loader">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped lang="scss">

.loader {
  text-align: center;

  .spinner {
    width: 100px;
    height: 100px;
    font-size: 50px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 102px;
    height: 102px;
    margin: 12px;
    border: 12px solid #666;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #666 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}</style>
