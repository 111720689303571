<template>
  <DxDataGrid :hover-state-enabled="true" :show-borders="false" :data-source="props.data" id="serviceGridContainer"
    :selected-row-keys="selectedKeys" :auto-navigate-to-focused-row="true" key-expr="id">
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxStateStoring :enabled="true" type="localStorage" storage-key="serviceGridContainer" />
    <DxColumn data-field="id" data-type="string" caption="ID" :visible="false" />
    <DxColumn data-field="blServiceName" data-type="string" caption="Name" />
    <DxColumn data-field="blpgCode" data-type="string" caption="BL PG Code" />
    <DxColumn data-field="poNumber" data-type="string" caption="PO number" />
    <DxColumn data-field="country.name" data-type="string" caption="Country" />
    <DxColumn type="buttons" caption="Actions" :width="70">
      <!-- <DxButton hint="Hide detail" icon="eyeclose" styling-mode="contained" type="normal" @click="clearDetail" /> -->
      <DxButton hint="View detail" icon="eyeopen" styling-mode="contained" type="normal" @click="showDetail" />
      <DxButton hint="Open link" icon="link" styling-mode="contained" type="normal" @click="openLink" />
    </DxColumn>
    <DxPaging :page-size="10" />
    <DxPager :show-page-size-selector="true" :allowed-page-sizes="[5, 10, 20]" />
  </DxDataGrid>
</template>

<script setup lang="ts">
import { ServiceItem } from "@/services/entities/ServiceItem";
import { showToastError, showWarning } from "@/toastification";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPaging, DxPager,
  DxHeaderFilter,
  DxStateStoring,
  DxButton
} from "devextreme-vue/data-grid";
import { ref } from "vue";

const emit = defineEmits(["detail"]);
const selectedKeys = ref([] as number[]);

const props = defineProps({
  data: { Type: [] as ServiceItem[], default: [] as ServiceItem[] },
});

function showDetail(e: any) {
  selectedKeys.value = [e.row.data.id];
  emit("detail", e.row.data.id);
}

function openLink(e: any) {
  let url = e.row.data.link;
  if (url) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    window.open(url, "_blank");
  }
  else {
    showWarning("No link available");
  }
}
</script>

<style scoped lang="scss">
.buttons {
  margin-bottom: 10px;

  b {
    margin-right: 3px;
  }
}
</style>
