<template>
    <div class="user-assignment">
        <div v-for="(item, index) in props.users" :key="getKey('users', index)">
            <vue-select v-model="item.userId" :options="userStore.getAll" label="email" :reduce="(item) => item.id"
                :appendToBody="true" :disabled="!state.edit"></vue-select>
            <div>
                <VueDatePicker v-for="(part, index) in item.participations" :key="getKey('part', index)" :teleport="true"
                    :min-date="props.projectStart" :max-date="props.projectEnd" v-model="part.monthRange" range month-picker
                    :disabled="!state.edit" @cleared="item.participations.splice(index, 1)"></VueDatePicker>
                <DxButton icon="add" text="Add range" v-if="state.edit" @click="item.participations.push({})" />
                <DxButton icon="trash" hint="Remove user" v-if="state.edit" @click="props.users.splice(index, 1)" />
            </div>
        </div>
        <div class="buttons">
            <div v-if="state.edit">
                <button @click="addUser" :class="{ accent: !state.edit }"><b>+</b>Add user</button>
                <button class="accent" @click="saveUsers()">Save</button>
            </div>
            <button v-else @click="state.edit = true" :class="{ accent: !state.edit }">Edit assignment</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { UserProjectItem, UserProjectParticipationItem } from "@/services/entities/UserProjectItem";
import { useUserStore } from "@/stores/user";
import { onMounted, reactive } from "vue";
import VueSelect from "vue-select";

import DxButton from 'devextreme-vue/button';
import { getKey } from "@/common/util";

const userStore = useUserStore();

const props = defineProps({
    users: { Type: [] as UserProjectItem[], default: [] as UserProjectItem[] },
    projectStart: Date,
    projectEnd: Date
});

onMounted(async () => {
    await userStore.loadAll();
});

const emit = defineEmits(['save'])

const state = reactive({
    edit: false
});

function saveUsers(): void {
    // props.users.forEach(element => {
    //     element.range = element.range.map((a: any) => new Date(a.year, a.month))
    // });

    let result = props.users.filter(a => a.userId && a.participations.length > 0);
    result.forEach(a => {
        a.participations.forEach((b: any) => {
            if (b.monthRange) {
                let c = b.monthRange[0];
                b.range = [] as any;
                b.range[0] = new Date(Date.UTC(c.year, c.month));
                c = b.monthRange[1]
                b.range[1] = new Date(Date.UTC(c.year, c.month + 1, 0));
            }
        });
    });
    emit('save', result);
}

function addUser(): void {
    props.users.push({ participations: [{} as UserProjectParticipationItem] } as unknown as UserProjectItem);
}
</script>

<style scoped lang="scss">
.v-select {
    width: 50%;
}

.dp__main {
    padding-bottom: 3px;
    padding-top: 3px;
}

.user-assignment {
    display: flex;
    flex-direction: column;
    width: 100%;

    &>div {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding: 1px;

        :first-child {
            margin-right: 5px;
        }

        &:not(:last-child) {
            border-bottom: 0.5px solid #BABABA;
        }
    }
}
</style>
<style>
.vs__selected-options {
    overflow: hidden;
}

.dp--year-select {
    width: unset !important;
}
</style>