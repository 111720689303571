import { defineStore } from "pinia";
import { ReportingMonth } from "@/services/entities/Reporting/ReportingMonth";
import ReportingDataFetcher from "@/services/connectors/reportingConnector";
import { getServerUrl } from "@/common/urls";
import {
  closeToast,
  showLoading,
  showSuccess,
  showToastError,
} from "@/toastification";
import { Comment } from "@/services/entities/Reporting/ReportingLineDay";
import { parseError } from "@/common/util";

const reporting = new ReportingDataFetcher(getServerUrl());

export const useReportingMonthStore = defineStore("reportingMonth", {
  state: () => ({
    reportingMonth: {
      data: {} as ReportingMonth,
      loader: false,
    },
  }),
  actions: {
    async loadReportingMonth(
      year: number,
      month: number,
      userId: string
    ): Promise<void> {
      try {
        this.reportingMonth.data = {} as ReportingMonth;
        this.reportingMonth.loader = false;
        this.reportingMonth.data = await reporting.getReportingMonth(
          year,
          month,
          userId
        );
        this.reportingMonth.data.lines.forEach((a) => {
          if (a.project) {
            a.project.projectLabel = a.project.projectNumber
              ? `${a.project.name} [${a.project.projectNumber}]`
              : a.project.name;
          }
          a.days.forEach((b) => {
            b.commentBox = {
              countryId: b.countryId,
              text: b.comment,
            } as Comment;
          });
        });
        this.reportingMonth.data.projects.forEach((element) => {
          element.projectLabel = element.projectNumber
            ? `${element.name} [${element.projectNumber}]`
            : element.name;
        });
      } catch (e: any) {
        console.error(e);
      } finally {
        this.reportingMonth.loader = true;
      }
    },
    async saveMonth(repMonth: ReportingMonth): Promise<void> {
      try {
        const save = showLoading("Saving month report");
        repMonth.lines.forEach((a) => {
          a.projectTypeId = a.project.projectTypeId;
          if (a.activity) {
            a.activityNumberId = a.activity?.id;
          }
          a.days.forEach((b) => {
            b.comment = b.commentBox.text;
            b.countryId = b.commentBox.countryId;
          });
        });
        await reporting.saveReportingMonth(repMonth);
        closeToast(save);
        showSuccess("Month report saved");
        await this.loadReportingMonth(
          repMonth.year,
          repMonth.month,
          repMonth.userId
        );
      } catch (e: any) {
        showToastError("Failed to save month report: " + parseError(e));
        console.error(e);
      }
    },
    clearReportingMonth(): void {
      this.reportingMonth.data = {} as ReportingMonth;
      this.reportingMonth.loader = false;
    },
  },
  getters: {
    getAll(): ReportingMonth {
      return this.reportingMonth.data;
    },
    getAllLoaded(): boolean {
      return !this.reportingMonth.loader;
    },
  },
});
