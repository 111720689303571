<template>
    <div contenteditable="true">
        <div id="page_0" class="page">
            <table>
                <tr>
                    <td>ABB Mexico SA de CV</td>
                </tr>
                <tr>
                    <td>Avenida Central No. 310, Parque Logístico,</td>
                </tr>
                <tr>
                    <td>C.P. 78395, San Luis Potosí, S.L.P.</td>
                </tr>
                <tr>
                    <td>MEXICO</td>
                </tr>
                <tr>
                    <td>AME920102SS4</td>
                </tr>
            </table>
            <div class="red-line"></div>
            <h3>Time sheet for invoicing</h3>
            <div class="d-row">
                <table>
                    <tr>
                        <td>Year / Month</td>
                        <td>{{ props.year }}/{{ $props.month }}</td>
                    </tr>
                    <tr>
                        <td>MXTEC Service name</td>
                        <td>{{ props.service.blServiceName }}</td>
                    </tr>
                    <tr>
                        <td>MXTEC SAP number</td>
                        <td>{{ props.service.sapNumber }}</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td>Customer Purchase order</td>
                        <td>{{ props.service?.poNumber }}</td>
                    </tr>
                    <tr>
                        <td>Customer BL PG code</td>
                        <td>{{ props.service?.blpgCode }}</td>
                    </tr>
                    <tr>
                        <td>Customer country</td>
                        <td>{{ settingsStore.getCountries.find(a => a.id == props.service.countryId)?.name }}
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <h4>
                    Summary by Engineers:
                </h4>
                <table>
                    <tr v-for="(item, index) in props.data.engineers" :key="getKey('eng_s', index)">
                        <td>{{ item.user?.fullName }}</td>
                        <td>Hours: {{ item.sumHours }}</td>
                        <td>Rate: {{ item.rate?.name }}</td>
                        <td>Sum: {{ (item.rate?.value ?? 0) * item.sumHours }}</td>
                    </tr>
                </table>
            </div>
            <div>
                <h4>
                    Summary by Rate Category:
                </h4>
                <table>
                    <tr v-for="(item, index) in props.data.rateSummaries" :key="getKey('rate_s', index)">
                        <td>{{ item.rate?.name }} - {{ item.rate?.value }}</td>
                        <td>Hours: {{ item.hours }}</td>
                        <td>Sum: {{ (item.rate?.value ?? 0) * item.hours }}</td>
                    </tr>
                    <tr>
                        <th>Sum invoice total:</th>
                        <th>{{ props.data.rateSummaries.reduce((sum, item) => sum + item.hours, 0) }} h</th>
                        <th>MXN {{ props.data.rateSummaries.reduce((sum, item) => sum + (item.rate?.value ?? 0) * item.hours, 0) }}
                        </th>
                    </tr>
                </table>
            </div>

            <div class="page-footer">
                1 / {{ totalPageCount.get() }}
            </div>
        </div>
        <div v-for="(item, index) in props.data.engineers" :key="getKey('eng', index + 1)" :id="getKey('eng', index + 1)">
            <div v-for="(jtem, jndex) in item.engineerActivities" :key="getKey('page', index + jndex)"
                :id="getKey('page', (getCurrentPage(index, jndex)))" class="page">
                <div class="eng-head">
                    <h4>{{ item.user?.fullName }} </h4>
                    <h4>{{ state.currency }} {{ jtem.dailyActivities.reduce((sum, activity) => sum + (activity.hours ?? 0), 0) * (item.rate?.value ?? 0) }}</h4>
                </div>
                <div class="proj-head">
                    <h4>Customer Project number: {{ jtem.project?.projectNumber }}</h4>
                    <h4>Customer Activity number: {{ jtem.dailyActivities[0].definition.activityNumber }}</h4>
                </div>
                <table>
                    <tr v-for="(row, jindex) in jtem.dailyActivities" :key="getKey('row', jindex)">
                        <td>{{ formatLocaleDate(row.date) }}</td>
                        <td>{{ row.hours ?? 0 }} h</td>
                        <td>{{ row.comment }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><b>{{ jtem.dailyActivities.reduce((sum, activity) => sum + (activity.hours ?? 0), 0) }} h</b>
                        </td>
                        <td>
                            <div>
                                <b>
                                    Rate: {{ item.rate?.name }} - {{ item.rate?.value }}</b>
                                <b class="sum-row">Sum: {{ state.currency }} {{ jtem.dailyActivities.reduce((sum, activity) => sum + (activity.hours ?? 0), 0) * (item.rate?.value ?? 0) }}</b>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="page-footer">
                    {{ getCurrentPage(index, jndex) + 1 }} / {{ totalPageCount.get() }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getKey } from "@/common/util";
import { useSettingsStore } from "@/stores/setting";
import { formatLocaleDate } from "@/common/date";
import { reactive } from "vue";

const props = defineProps(['data', 'year', 'month', "service"]);
const settingsStore = useSettingsStore();

const state = reactive({
    date: { year: (new Date).getFullYear(), month: (new Date).getMonth() },
    currency: "MXN"
});

const getCurrentPage = (index: number, jndex: number) => {
    let count = 0;
    props.data.engineers.forEach((item: any, i: number) => {
        if (i < index) {
            count += item.engineerActivities.length;
        } else if (i == index) {
            count += jndex + 1;
        }
    });
    return count;
}

const totalPageCount = {
    get: () => {
        let result = 1;
        for (let i = 0; i < props.data.engineers.length; i++) {
            result += props.data.engineers[i].engineerActivities.length;
        }
        return result;
    }
}


</script>

<style scoped lang="scss">
.page {
    height: 900px;
    width: 660px;
    border: 0 solid black;
    box-shadow: 0px 0px 2px 1px black;
    margin: 5px;
    margin-bottom: 10px;
    padding: 5px;
    position: relative;
}

.page-footer {
    position: absolute;
    bottom: 0;
    right: 5px;
}

span,
td {
    font-size: 12px;
    line-height: 10px;
    min-width: 30px;
}

th {
    font-size: 14px;
    line-height: 20px;
    text-align: start;
}

table {
    width: 100%;
}

h3 {
    font-size: 14px;
}

.red-line {
    margin-top: 5px;
    margin-bottom: -10px;
    border-bottom: 2px var(--foreground-accent-default) solid;
    width: 30%;
}

h4 {
    font-size: 13px;
    margin-bottom: 0;
}

.eng-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px black;
    margin-bottom: 10px;
}

.proj-head {
    h4 {
        margin-bottom: 0;
        line-height: 0;
    }

    margin-bottom: 20px;
}

.sum-row {
    padding: 30px;
}
</style>