import ApiConnector from "../apiConnector";
import { EnumValues } from "../entities/EnumValues";

class OvervieDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor(baseURL: string) {
    this.apiConnector = new ApiConnector(baseURL);
  }

  public async getProjectTypes(): Promise<EnumValues[]> {
    return await this.apiConnector.get<EnumValues[]>(
      "overview/enum/projectType"
    );
  }

  public async getNonBillableProjectType(): Promise<EnumValues[]> {
    return await this.apiConnector.get<EnumValues[]>(
      "overview/enum/nonBillableProjectType"
    );
  }

  public async getInvoiceType(): Promise<EnumValues[]> {
    return await this.apiConnector.get<EnumValues[]>(
      "overview/enum/invoiceType"
    );
  }
}

export default OvervieDataFetcher;
