<template>
  <div class="report-view">
    <div class="header">
      <span class="title">{{ Authorization.accountName }}'s reporting</span>
      <VueDatePicker :teleport="true" v-model="state.date" month-picker :clearable="false" @update:model-value="loadMonth">
      </VueDatePicker>
    </div>
    <TileCard id="report-table" :loaded="!reportingStore.getAllLoaded">
      <template #body>
        <ReportTable v-if="reportingStore.getAll.projects.length > 0" :reportingMonth="reportingStore.getAll" :daily-commitment="state.dailyCommitment"> <template
            #buttons>
            <button v-if="!checkApproved()" @click.once="sendForApproval">Send for approval</button>
            <button v-if="!checkApproved()" class="accent" @click.once="save">Save</button></template></ReportTable>
        <div v-else>No projects assigned</div>
      </template>
    </TileCard>
    <div class="d-row user-status" v-if="!reportingStore.getAllLoaded">
      <OvertimeOverview :overtimeModel="overtimeStore.getModel" :loaded="overtimeStore.getModelLoaded"></OvertimeOverview>
      <MonthlyHistory :year="state.date.year" :month="state.date.month" :userId="reportingStore.getAll.userId">
      </MonthlyHistory>
    </div>
    <div></div>
  </div>
</template>

<script setup lang="ts">
import ReportTable from "@/components/tables/ReportTable.vue";
import OvertimeOverview from "@/components/OvertimeOverview.vue";
import MonthlyHistory from "@/components/MonthlyHistory.vue";
import TileCard from "@/components/util/TileCard.vue";
import { onBeforeMount, onUnmounted, reactive } from "vue";
import { useReportingMonthStore } from "@/stores/reporting";
import { Authorization } from "@/services/authenticate";
import { MonthlyApprovalItem, ApprovalStatus } from "@/services/entities/Approval/MonthlyApprovalItem"
import { useApprovalStore } from "@/stores/approval"
import { useOvertimeStore } from "@/stores/overtime"
import { showToastError } from "@/toastification";
import { useUserStore } from "@/stores/user";

const reportingStore = useReportingMonthStore();
const approvalStore = useApprovalStore();
const overtimeStore = useOvertimeStore();
const userStore = useUserStore();

const state = reactive({
  date: { year: (new Date).getFullYear(), month: (new Date).getMonth() },
  dailyCommitment: 0
});

onBeforeMount(async () => {
  state.dailyCommitment = userStore.getLoggedUser.dailyCommitments
                            .filter(a => a.year <= state.date.year && a.month <= state.date.month)
                            .sort((a, b) => b.year - a.year).sort((a, b) => b.month - a.month)[0]?.hours ?? 0;
  await reportingStore.loadReportingMonth(state.date.year, state.date.month + 1, Authorization.id);
  await overtimeStore.loadOvertimeModel(state.date.year, state.date.month + 1, Authorization.id);
});

onUnmounted(() => {
  reportingStore.clearReportingMonth();
});

async function loadMonth() {
  state.dailyCommitment = userStore.getLoggedUser.dailyCommitments
                            .filter(a => a.year <= state.date.year && a.month <= state.date.month)
                            .sort((a, b) => b.year - a.year).sort((a, b) => b.month - a.month)[0]?.hours ?? 0;
  await reportingStore.loadReportingMonth(
    state.date.year,
    state.date.month + 1,
    Authorization.id
  );
  await overtimeStore.loadOvertimeModel(state.date.year, state.date.month + 1, Authorization.id);
}

async function save() {
  // Check if there are any lines without ActivityNumberId
  if (reportingStore.getAll.lines.some(a => (a.projectTypeId == 0 || a.projectTypeId == 1) && !a.activity)) {
    showToastError("Please select activity for all projects");
    return;
  }
  // Group by project and activity, then count the number of lines, if more than 1, show error
  let grouped = reportingStore.getAll.lines.reduce((a, b) => {
    let key = `${b.project?.projectId}-${b.activity?.id}`;
    if (!a[key]) {
      a[key] = 0;
    }
    a[key]++;
    return a;
  }, {} as { [key: string]: number });
  let errors = Object.keys(grouped).filter(a => grouped[a] > 1);
  if (errors.length > 0) {
    showToastError("There are multiple lines for the same project and activity");
    return;
  }
  reportingStore.getAll.lines.forEach(a => {
    a.projectId = a.project?.projectId;
    if (a.activity) {
      a.activityNumberId = a.activity?.id;
    }
    if (a.projectTypeId > 90 || a.projectTypeId == 2) {
      a.activityTypeId = 0;
      a.activityNumberId = reportingStore.getAll.projects.find(b => b.projectId == a.projectId)?.activityNumbers[0]?.id ?? "";
    }
    a.days.forEach(b => {
      if (typeof (b.hours) == 'string') {
        b.hours = null;
      }
    })
  });

  reportingStore.getAll.actionAuthor = Authorization.id;
  reportingStore.getAll.lines = reportingStore.getAll.lines.filter(a => a.project);
  await reportingStore.saveMonth(reportingStore.getAll);
  await overtimeStore.loadOvertimeModel(state.date.year, state.date.month + 1, Authorization.id);
}

function checkApproved() {
  return (
    (reportingStore.getAll.approvalStatus.id as ApprovalStatus) ==
    ApprovalStatus.Approved
  );
}


async function sendForApproval() {
  let mA = {} as MonthlyApprovalItem;
  mA.year = state.date.year;
  mA.month = state.date.month + 1;
  mA.userId = Authorization.id;
  mA.status = ApprovalStatus.SentForApproval

  await approvalStore.saveApproval(mA, Authorization.id);
  await reportingStore.loadReportingMonth(state.date.year, state.date.month + 1, Authorization.id);
  await overtimeStore.loadOvertimeModel(state.date.year, state.date.month + 1, Authorization.id);
}
</script>

<style scoped lang="scss">
.report-view {
  overflow-y: auto;
  height: 88vh;
}

.user-status {
  min-height: 10rem;

  &>div {
    max-height: 15rem;
    overflow: auto;
  }
}
</style>