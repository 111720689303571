<template>
    <div class="holiday-form">
        <div v-for="(item, index) in props.value" :key="getKey('holiday', index)">
            <div>
                <label>Day of holiday</label>
                <VueDatePicker :teleport="true" v-model="item.date" :enable-time-picker="false" :clearable="false"
                    @update:model-value="checkDate">
                </VueDatePicker>
            </div>
            <div>
                <label :for="getKey('holiday', index) + '_n'">Name</label><input type="string"
                    :key="getKey('holiday', index) + '_n'" v-model="item.name" />
            </div>
            <div>
                <label :for="getKey('holiday', index) + '_n'">Description</label><input type="string"
                    :key="getKey('holiday', index) + '_n'" v-model="item.description" />
            </div>
        </div>
    </div>
    <div class="buttons">
        <slot name="buttons"></slot>
    </div>
</template>

<script setup lang="ts">
import { getKey } from "@/common/util";
import { HolidayItem } from "@/services/entities/HolidayItem";
import { useSettingsStore } from "@/stores/setting";

const settingStore = useSettingsStore();

const props = defineProps({
    value: { Type: [] as HolidayItem[], default: [] as HolidayItem[] },
});

function checkDate(item: Date) {
    item = new Date(item.toDateString());
    if (settingStore.getHolidays.some(a => new Date(a.date) == item)) {

    }
}
</script>

<style scoped lang="scss">
.holiday-form {
    display: flex;
    flex-wrap: wrap;

    &>div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-right: solid 1px var(--background-alternative);
        border-bottom: solid 1px var(--background-alternative);
        padding-right: 5px;

        &>div {
            margin-right: 15px;
        }
    }

    .dp__main {
        width: 80%;
    }
}
</style>