import { useToast } from "vue-toastification";
import { ToastID } from "vue-toastification/dist/types/types";

const toast = useToast();
export function showToastError(text: string) {
  toast.error(text, {
    timeout: 0,
  });
}
export function showLoading(text: string, timeout: number = 3500) {
  return toast.info(text, {
    timeout: timeout,
  });
}
export function showWarning(text: string) {
  return toast.warning(text, {
    timeout: 0,
  });
}
export function showSuccess(text: string) {
  return toast.success(text, {
    timeout: 3000,
  });
}
export function closeToast(id: ToastID) {
    toast.dismiss(id);
}
