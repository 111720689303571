<template>
  <DxDataGrid :hover-state-enabled="true" :show-borders="false" :data-source="props.data.approvalUsers"
    id="userApprovalGridContainer" :reduce="(item) => item.userItem" :selected-row-keys="selectedKeys" @selectionChanged="selection"
    :auto-navigate-to-focused-row="true" key-expr="userItem.id">
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxStateStoring :enabled="true" type="localStorage" storage-key="userApprovalGridContainer" />
    <DxSelection select-all-mode="allPages" show-check-boxes-mode="always" mode="multiple" :width="30" />
    <DxColumn data-field="userItem.id" data-type="string" caption="Id" :visible="false" />
    <DxColumn data-field="userItem.fullName" data-type="string" caption="Name" />
    <DxColumn data-field="approvalStatus.name" data-type="string" caption="State" />
    <DxColumn data-field="userItem.teamLeader.fullName" caption="TL" :width="50" cell-template="grid-cell-template" />
    <DxColumn type="buttons" caption="Actions" :width="70">
      <!-- <DxButton hint="Hide detail" icon="eyeclose" styling-mode="contained" type="normal" @click="clearDetail" /> -->
      <DxButton hint="View detail" icon="eyeopen" styling-mode="contained" type="normal" @click="showDetail" />
    </DxColumn>
    <DxPaging :page-size="10" />
    <DxPager :show-page-size-selector="true" :allowed-page-sizes="[5, 10, 20]" />
    <template #grid-cell-template="{ data }">
      <div class="photo-template">
        <img :src="createBlobUrl(data.data)" alt="Direct manager" class="person-photo person-photo--rounded"
          :title="data.data.userItem.teamLeader?.fullName" />
      </div>
    </template>
  </DxDataGrid>
</template>

<script setup lang="ts">
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPaging,
  DxStateStoring,
  DxHeaderFilter, 
  DxPager,
  DxButton,
  DxSelection
} from "devextreme-vue/data-grid";
import { UserApprovalModel } from "@/services/entities/Approval/UserApprovalModel"
import { onBeforeMount, ref } from "vue";
import { useAzureStore } from "@/stores/azure";

const emit = defineEmits(["detail", "selection"]);

const selectedKeys = ref([] as number[]);
const azureStore = useAzureStore();

onBeforeMount(async () => {
  console.log("Loading user photos", props.data.approvalUsers);
  if (props.data.approvalUsers && props.data.approvalUsers.length > 0) {
    await azureStore.loadUserPhotos(props.data.approvalUsers.map((a) => a.userItem.teamLeaderId));
    props.data.approvalUsers.forEach((a: any) => {
      a.photo = azureStore.getUsersPhotos.find((p: any) => p.id === a.userItem.teamLeaderId)?.blob;
    });
  }
});

function selection(e: any){
  console.log("Selection changed", e);
  emit("selection", e.selectedRowsData);
}

const createBlobUrl = (data: any) => {
  console.log("Creating blob url", data);
  if (data.photo) {
    return URL.createObjectURL(data.photo);
  }
  return undefined;
};

const props = defineProps({
  data: { Type: {} as UserApprovalModel, default: {} as UserApprovalModel }
});

function showDetail(e: any) {
  selectedKeys.value = [e.row.data.userItem.id];
  emit("detail", e.row.data.userItem.id);
}

function clearDetail(e: any) {
  emit("detail", null);
}

</script>

<style scoped lang="scss">
.photo-template {
  display: flex;
  justify-content: center;


  .person-photo {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
  }
}
</style>
