<template>
  <div class="table">
    <div class="selects">
      <div class="title">
        <span>Project name</span>
        <span>Activity name</span>
        <!-- <span>Activity type</span> -->
      </div>
      <div v-for="(line, lineIndex) in props.reportingMonth.lines" :key="getKey('selects', lineIndex)"
        class="project-type">
        <DxButton v-if="!line.days.some((a) => (a.hours ?? 0) > 0 || a.status)" icon="trash" class="line-delete"
          @click="deleteLine(lineIndex)" />
        <div class="project-name">
          <vue-select label="projectLabel" v-if="line.projectTypeId >= 90" :options="props.reportingMonth.projects.filter((a) => a.projectTypeId >= 90)
            " :clearable="false" :disabled="line.days.some(
              (a) =>
                ((a.hours ?? 0) > 0 || a.status) &&
                state.lockedDays.includes(a.day)
            )
              " v-model="line.project" />
          <Popper v-else-if="line.projectTypeId == 0" :content="line.project?.description" class="tooltip" hover
            placement="top" openDelay="200" closeDelay="100">
            <vue-select label="projectLabel" :options="props.reportingMonth.projects.filter(
              (a) => a.projectTypeId <= 1
            )
              " :clearable="false" @option:selected="line.activity = null" :disabled="line.days.some(
                (a) =>
                  ((a.hours ?? 0) > 0 || a.status) &&
                  state.lockedDays.includes(a.day)
              )
                " v-model="line.project" />
          </Popper>
          <vue-select label="projectLabel" v-else :options="props.reportingMonth.projects.filter(
            (a) => a.projectTypeId == line.projectTypeId
          )
            " :clearable="false" :disabled="line.days.some(
              (a) =>
                ((a.hours ?? 0) > 0 || a.status) &&
                state.lockedDays.includes(a.day)
            )
              " v-model="line.project" />
        </div>
        <div v-if="
          line.project && line.projectTypeId < 90 && line.projectTypeId != 2
        " class="project-activity">
          <Popper :content="line.activity?.description" class="tooltip" hover placement="top" openDelay="200"
            closeDelay="100">
            <vue-select label="activityNumber" :options="props.reportingMonth.projects?.find(
              (a) => a.projectId == line.project.projectId
            )?.activityNumbers
              " :clearable="false" v-model="line.activity" :disabled="line.days.some(
                (a) => (a.hours ?? 0) > 0 && state.lockedDays.includes(a.day)
              )
                " :class="{
                  'border-red':
                    !line.activity || getActivityCount(line.activity?.id) > 1,
                }"> </vue-select>
          </Popper>
        </div>
        <div v-else-if="
          line.project &&
          props.reportingMonth.projects.find(
            (a) => a.projectId == line.project.projectId
          )?.projectTypeId == 99 &&
          state.remainingVacation
        ">
          <span>Remaining {{ state.remainingVacation }}
            {{ state.remainingVacation > 1 ? "days" : "day" }}</span>
        </div>
        <div v-else-if="
          line.project &&
          props.reportingMonth.projects.find(
            (a) => a.projectId == line.project.projectId
          )?.projectTypeId == 98 &&
          state.remainingVacation
        ">
          <span>Remaining {{ state.floatingDays }}
            {{ state.floatingDays > 1 ? "days" : "day" }}</span>
        </div>
      </div>
      <div class="project-type">
        <div class="buttons">
          <button @click="addLine(0)"><b>+</b>Add project</button>
          <button @click="addLine(99)"><b>+</b>Add absence</button>
          <button @click="addLine(2)"><b>+</b>Add non invoiced project</button>
        </div>
        <div class="project-activity">
          <div class="info-box">
            <div class="info">
              Day Sum
            </div>
            <div class="info">
              Floating OT
            </div>
            <div class="info">
              Project OT
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar">
      <div class="dates">
        <div class="content" v-for="(item, index) in props.reportingMonth.lines" :key="getKey('date', index)" :class="{
          first: index == 0,
          checkbox: item.project?.isCheckbox,
          checoBoxBox: item.project?.isCheckbox,
        }">
          <div v-if="item.project">
            <div :index="index" v-for="(day, dayIndex) in item.days" :key="getKey(getKey('date', index), dayIndex)"
              :class="{
                dayOff: !day.isWorkDay,
              }">
              <Popper v-if="index == 0" hover placement="top" class="tooltip"
                :content="getHolidayNameAndDesc(dayIndex + 1)">
                <span> {{ dayIndex + 1 }}</span>
              </Popper>
              <input v-if="item.project?.isCheckbox" type="checkbox" class="small" v-model="day.status"
                :disabled="state.lockedDays.includes(dayIndex + 1)" />
              <input v-else type="number" class="small" max="24" v-model="day.hours" min="0"
                @input="checkInput(index, dayIndex)" :disabled="state.lockedDays.includes(dayIndex + 1)" />
              <Popper v-if="
                !item.project?.isCheckbox && day.commentBox?.text?.length > 0
              " :content="day.commentBox.text" class="tooltip" hover openDelay="200" closeDelay="100">
                <div class="comment filled" @click="() => {
                  showComment(index, dayIndex);
                }
                  "></div>
              </Popper>
              <div class="comment" v-else-if="!item.project?.isCheckbox" @click="() => {
                showComment(index, dayIndex);
              }
                "></div>
            </div>
          </div>
        </div>
        <div class="content day-info">
          <div>
            <DayInfo v-for="(item, i) in props.reportingMonth.lines[0].days" :key="'sum_' + i" :classa="0" :class="{
              dayOff: !item.isWorkDay,
            }" :value="getSumAndOT(
              props.reportingMonth.lines.map((a) => a.days[i]?.hours),
              props.reportingMonth.lines.map((a) => a.days[i]?.isWorkDay),
              i
            )
              " :threshold="item.isWorkDay ? props.dailyCommitment : null"></DayInfo>
          </div>
        </div>
        <div class="content">
          <div v-for="(item, i) in props.reportingMonth.overtimes" :key="getKey('suma_', i)"
            :class="{ first: i == 0, dayOff: item.isWorkday }">
            <div>
              <div>
                <input type="number" class="small-22" max="24" v-model="item.projectOvertime"
                  @input="checkProjectOvertimeInput(i)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sum">
        <div class="content" v-for="(item, index) in props.reportingMonth.lines" :key="getKey('date', index)"
          :class="{ first: index == 0, checkbox: item.project?.isCheckbox }">
          <div v-if="item.project">
            <div v-if="!item.project?.isCheckbox">
              <span v-if="index == 0">SUM</span>
              <input type="number" class="wider-22" max="24" disabled :value="item.days
                .map((a) => a.hours)
                .reduce((sum, a) => (sum ?? 0) + Number(a), 0)
                " />
            </div>
          </div>
        </div>
        <DayInfo :value="getSumSumAndOT(props.reportingMonth.lines)" :threshold="props.reportingMonth.lines[0].days.filter((a) => a.isWorkDay)
          .length * props.dailyCommitment
          " :classa="1"></DayInfo>
        <input type="number" class="wider-22" disabled :value="getProjectOtSum(props.reportingMonth.overtimes)" />
      </div>
    </div>
  </div>
  <div class="buttons">
    <button @click="getMonthlyUserReport">Export to excel</button>
    <slot name="buttons"></slot>
  </div>
  <CommentModal v-if="props.reportingMonth.lines" :modal-id="commentModalId" v-model="props.reportingMonth.lines[state.activeLine.line].days[state.activeLine.day]
    .commentBox
    " :disabled="state.lockedDays.includes(state.activeLine.day + 1)" @close="vfm.close(commentModalId)" />
  <LoadingDownloadScreen v-if="state.reportLoading"></LoadingDownloadScreen>
</template>

<script setup lang="ts">
import VueSelect from "vue-select";
import LoadingDownloadScreen from "@/components/util/LoadingDownloadScreen.vue";
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import { ReportingMonth } from "@/services/entities/Reporting/ReportingMonth";
import { getKey } from "@/common/util";
import ActivityAssignmentModal from "@/components/modals/ActivityAssignmentModal.vue";
import { useReportingMonthStore } from "@/stores/reporting";
import { ReportingLine } from "@/services/entities/Reporting/ReportingLine";
import { ReportingLineDay } from "@/services/entities/Reporting/ReportingLineDay";
import CommentModal from "@/components/modals/CommentModal.vue";
import DayInfo from "@/components/report/DayInfo.vue";
import OvertimeDayInfo from "@/components/report/OvertimeDayInfo.vue";
import { useVfm } from "vue-final-modal";
import { useUserStore } from "@/stores/user";
import { useProjectStore } from "@/stores/project";
import { ProjectSelect } from "@/services/entities/Reporting/ProjectSelect";
import { Authorization } from "@/services/authenticate";
import DxButton from "devextreme-vue/button";
import Tooltip from "../util/Tooltip.vue";
import OvertimeDayInfoVue from "../report/OvertimeDayInfo.vue";
import { ReportingOvertime } from "@/services/entities/Reporting/ReportingOvertime";
import { showToastError } from "@/toastification";
import Popper from "vue3-popper";
import { useReportStore } from "@/stores/report";
import { parseError } from "@/common/util";
import { ActivityNumberSelect } from "@/services/entities/Reporting/ActivityNumberSelect";
import { DefinitionItem } from "@/services/entities/DefinitionItem";

const vfm = useVfm();
const commentModalId = Symbol("commentModalId");
const activityAssignemtnId = Symbol("activityAssignemtnId");

const reportingStore = useReportingMonthStore();
const userStore = useUserStore();
const reportStore = useReportStore();

const props = defineProps({
  reportingMonth: { Type: {} as ReportingMonth, default: {} as ReportingMonth },
  dailyCommitment: { Type: Number, default: 0 },
});

const state = reactive({
  monthlyRecord: [{ day: [] }, { day: [] }, { day: [] }] as any[],
  reportingMonth: { Type: {} as ReportingMonth, default: {} as ReportingMonth },
  activeLine: { line: 0, day: 0 },
  remainingVacation: 0,
  floatingDays: 0,
  lockedDays: [] as number[],
  reportLoading: false,
});

async function getMonthlyUserReport() {
  state.reportLoading = true;
  try {
    await reportStore.downloadMonthlyUser(
      props.reportingMonth.year,
      props.reportingMonth.month,
      props.reportingMonth.userId,
      props.reportingMonth.userName
    );
  } catch (e) {
    console.error(e);
    showToastError("Report was unable to generate: " + parseError(e));
  } finally {
    state.reportLoading = false;
  }
}

onMounted(async () => {
  getLockedDays();
});

onMounted(async () => {
  await userStore.loadUser(props.reportingMonth.userId);
  const vacation = userStore.getUser.flexiVacations?.find(
    (a) => a.year == props.reportingMonth.year
  );
  if (vacation) state.remainingVacation = vacation.amount - vacation.balance;
  const floatingDays = userStore.getUser.floatingDays?.find(
    (a) => a.year == props.reportingMonth.year
  );
  if (floatingDays)
    state.floatingDays = floatingDays.amount - floatingDays.balance;
});

function showComment(line: number, day: number) {
  state.activeLine.line = line;
  state.activeLine.day = day;
  vfm.open(commentModalId);
}

async function save() {
  if (props.reportingMonth?.lines.some((a) => a.projectTypeId > 90)) {
    props.reportingMonth?.lines.forEach((a) => {
      a.projectId = a.project?.projectId;
      if (a.projectTypeId > 90 || a.projectTypeId == 2) {
        a.activityTypeId = 0;
        a.activityNumberId =
          props.reportingMonth.projects.find((b) => b.projectId == a.projectId)
            ?.activityNumbers[0]?.activityId ?? "";
      }
    });
  }
  props.reportingMonth.actionAuthor = Authorization.id;
  await reportingStore.saveMonth(props.reportingMonth);
}
// -1 == left, 1 == right, 0 == current - to je weird jo
function checkAdjacentDay(
  days: ReportingLineDay[],
  dayIndex: number,
  lineIndex: number,
  linesCount: number,
  direction: number
) {
  if (!days[dayIndex]?.isWorkDay && lineIndex == 0) {
    if (dayIndex < 1 && (direction == -1 || direction == 0)) {
      return days[dayIndex]?.isWorkDay;
    } else if (dayIndex < 1 && direction == 1) {
      return days[dayIndex + direction]?.isWorkDay;
    }

    return days[dayIndex + direction]?.isWorkDay;
  }
  return false;
}

function addLine(projectType = 0) {
  let newLine = {
    projectTypeId: projectType,
  } as ReportingLine;
  newLine.days = Array.from(
    { length: props.reportingMonth.daysInMonth },
    (_, index) => ({
      isWorkDay: props.reportingMonth.lines[0].days[index].isWorkDay,
      day: index + 1,
      hours: null,
      status: null,
      comment: null,
      commentBox: {
        countryId: 144,
        text: null,
      },
    })
  );
  props.reportingMonth.lines.push(newLine);
}

function deleteLine(index: number) {
  if (props.reportingMonth.lines.length == 1) {
    showToastError("You cannot delete the last line");
    return;
  } else {
    props.reportingMonth.lines.splice(index, 1);
  }
}

function getActivityCount(activityId: string) {
  return props.reportingMonth.lines.reduce(
    (count, line) => count + (line.activity?.id === activityId ? 1 : 0),
    0
  );
}

function getLockedDays() {
  for (const interval of props.reportingMonth.lockHistoryItems.filter(
    (a) => a.isLocked
  )) {
    const startDate = new Date(interval.dateFrom);
    const endDate = new Date(interval.dateTo);

    const currentDay = new Date(startDate);
    while (currentDay <= endDate && currentDay >= startDate) {
      state.lockedDays.push(currentDay.getDate());
      currentDay.setDate(currentDay.getDate() + 1);
    }
  }
}

function getSumAndOT(
  line: (number | null | undefined)[],
  isWorkDay: boolean[],
  i: number
) {
  const day = {
    sum: line?.reduce((sum, a) => (sum ?? 0) + Number(a), 0),
    ot: 0,
  };
  const fOt =
    props.reportingMonth.lines.find((line) => line.projectTypeId == 97)?.days[i]
      .hours ?? 0;
  day.ot = !isWorkDay[0]
    ? day.sum ?? 0
    : (day.sum ?? 0) > 0
      ? (day.sum ?? 0) - props.dailyCommitment
      : 0;

  if (props.reportingMonth.overtimes[i] != undefined) {
    props.reportingMonth.overtimes[i].floatingOvertime = day.ot;
  }
  day.ot = day.ot - fOt - props.reportingMonth.overtimes[i]?.projectOvertime;
  return day;
}

function getSumSumAndOT(line: ReportingLine[]) {
  const day = {
    sum: line
      .map((a) =>
        a.days
          ?.map((b) => b.hours)
          .reduce((sum, b) => (sum ?? 0) + Number(b), 0)
      )
      .reduce((sum, b) => (sum ?? 0) + Number(b), 0),
    ot: 0,
  };
  // working day hours for all days in month
  const workingDaysHours =
    line[0].days
      .map((b) => b.isWorkDay)
      .reduce((sum, b) => (sum ?? 0) + Number(b), 0) * props.dailyCommitment;

  // get floating overtime including project overtime that was not deducted 
  const fOt =
    props.reportingMonth.overtimes.reduce((sum, ot) => sum + ot.floatingOvertime, 0);

  // Deduct project overtime from floating overtime
  day.ot = fOt - props.reportingMonth.overtimes.reduce((sum, ot) => sum + (!Number(ot.projectOvertime) || ot.projectOvertime < 0 ? 0 : ot.projectOvertime ?? 0), 0);

  return day;
}

function getProjectOtSum(overtime: ReportingOvertime[]) {
  return overtime.reduce((sum, ot) => sum + (!Number(ot.projectOvertime) ? 0 : ot.projectOvertime ?? 0), 0);
}

function getDaysInMonth() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Month is zero-based, so we add 1
  return new Date(year, month, 0).getDate();
}

function checkInput(index: number, i: number) {
  let input = props.reportingMonth.lines[index].days[i].hours;
  if (input) {
    if (input > 24) {
      showToastError("Day does not have more than 24 hours");
      props.reportingMonth.lines[index].days[i].hours = 0;
    } else if (input < 0) {
      showToastError("Day value cannot be bellow zero");
      props.reportingMonth.lines[index].days[i].hours = 0;
    }
    else if (props.dailyCommitment % 1 == 0 && input % 1 != 0) {
      showToastError("Only full hours are allowed");
      props.reportingMonth.lines[index].days[i].hours = input - (input % 1);
    }
    else if (props.dailyCommitment % 1 == 0.5 && input % 1 != 0.5 && input % 1 != 0) {
      showToastError("Only half or full hours are allowed");
      props.reportingMonth.lines[index].days[i].hours = input - (input % 1);
    } else {
      props.reportingMonth.lines[index].days[i].hours = input;
    }
  } else {
    props.reportingMonth.lines[index].days[i].hours = input;
  }
}

function checkOvertimeCompInput(i: number) {
  let input = props.reportingMonth.overtimes[i].projectOvertime;
  if (input) {
    if (input > props.dailyCommitment) {
      showToastError(`You can only compensate ${props.dailyCommitment} hours per day`);
      props.reportingMonth.overtimes[i].projectOvertime = 0;
    } else if (input < 0) {
      showToastError("Overtime compensation cannot be bellow zero");
      props.reportingMonth.overtimes[i].projectOvertime = 0;
    }
    else if (props.dailyCommitment % 1 == 0.5 && input % 1 != 0.5 && input % 1 != 0) {
      showToastError("Only half or full hours are allowed");
      props.reportingMonth.overtimes[i].projectOvertime = input - (input % 1);
    } else {
      props.reportingMonth.overtimes[i].projectOvertime = input;
    }
  } else {
    props.reportingMonth.overtimes[i].projectOvertime = input;
  }
}

async function createAssignment(projectId: string, value: DefinitionItem) {
  vfm.close(activityAssignemtnId);
  const projectStore = useProjectStore();
  await projectStore.addDefinitions(projectId, value);
  await reportingStore.loadReportingMonth(
    props.reportingMonth.year,
    props.reportingMonth.month,
    props.reportingMonth.userId
  );
}

function getHolidayNameAndDesc(dayIndex: number) {
  const result = props.reportingMonth.holidays.find(
    (a) => new Date(a.date).getDate() == dayIndex
  );
  if (result) {
    return `${result.name} | ${result.description}`;
  } else {
    return;
  }
}

function checkProjectOvertimeInput(i: number) {
  let input = props.reportingMonth.overtimes[i].projectOvertime;
  let maxOt = props.reportingMonth.overtimes[i].floatingOvertime;
  if (input) {
    if (input > maxOt) {
      showToastError("You can only take " + maxOt + " hours of overtime");
      props.reportingMonth.overtimes[i].projectOvertime = 0;
    } else if (input < 0) {
      showToastError("Project overtime cannot be bellow zero.");
      props.reportingMonth.overtimes[i].projectOvertime = 0;
    } else if (input % 1 != 0 && props.dailyCommitment % 1 != 0.5) {
      showToastError("Decimal values are not allowed");
      props.reportingMonth.overtimes[i].projectOvertime = input - (input % 1);
    } else {
      props.reportingMonth.overtimes[i].projectOvertime = input;
    }
  } else {
    props.reportingMonth.overtimes[i].projectOvertime = input;
  }
}
</script>

<style scoped lang="scss">
.comment {
  width: 25px;
  height: 3px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 2px solid #dbdbdb;
  margin-left: 2px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &.filled {
    background: var(--background-secondary-active, rgba(0, 0, 0, 0.24));
    margin-right: 1.2px;
    margin-top: -13px;
  }
}

.table {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;

  &>.selects {
    display: flex;
    flex-direction: column;

    .project-activity {
      margin-left: 5px;
    }

    .title {
      font-size: 15px;
      display: flex;
      // margin-bottom: 7px;
      height: 19px;

      span {
        margin-right: 0.5rem;

        &:first-child {
          width: 21rem;
        }

        &:not(:first-child) {
          width: 6.5rem;
        }
      }
    }

    &>div:not(.title),
    &>div:not(.title)>div {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      height: 50px;
    }

    .project-name>div,
    .project-activity>div {
      margin-right: 0.25rem;
    }

    .project-name>div {
      width: 21rem;
    }

    .project-activity>div {
      width: 7rem;
    }

    .project-type span {
      margin-top: 0.65rem;
      font-size: 12px;
    }

    .line-delete {
      background-color: transparent !important;
      margin-left: -26px;
      width: 1.8rem;
      height: 2rem !important;
    }

    .buttons {
      flex-wrap: wrap;

      button {
        margin-bottom: 0.5rem;
      }
    }
  }

  &>.calendar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    overflow: hidden;
    width: calc(100% - 4.6rem);

    &>div {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      overflow-x: auto;
      height: 100%;
      padding-right: 0.4rem;

      &>.header {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        &>div {
          width: 50px;
          gap: 8px;
        }
      }

      &.sum {
        width: 3.2rem;
        overflow: visible;
      }

      &>.content {
        display: flex;
        flex-direction: row;
        justify-items: center;
        height: 50px;

        .wider-22 {
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          width: 35px;
        }

        &.day-info {
          height: 73px !important;
        }

        &>.day-info {
          padding-left: 0px;
          width: 27px;
          text-align: center;
          padding-right: 0px;
        }

        .tooltip {
          :deep(.popper) {
            background: var(--background-secondary-second);
            padding: 20px;
            border-radius: 20px;
            color: #fff;
          }
        }

        &.first {
          height: 71px;
        }

        &>div {

          input {
            padding-left: 0px;
            width: 27px;
            text-align: center;
            padding-right: 0px;
          }

          input,
          span {
            margin-left: 1.5px !important;
          }

          span {
            margin-top: -15px;
          }

          &>div {
            width: 2rem;
            text-align: center;
          }

          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}

.dayOff {
  background-color: var(--background-day-off, #ff00111a);

  &:first {
    border-top-left-radius: 5px;
  }
}

.bLeft {
  border-top-left-radius: 5px;
}

.bRight {
  border-top-right-radius: 5px;
}

.bBLeft {
  border-bottom-left-radius: 5px;
}

.bBRight {
  border-bottom-right-radius: 5px;
}

.dates {
  text-align: center;
}

.tooltip {
  :deep(.popper) {
    background: var(--background-secondary-second);
    padding: 20px;
    border-radius: 20px;
    color: #fff;
  }
}

.add-activity {
  cursor: pointer;
  color: var(--accent-color);
  font-style: italic;
  width: 100%;
  font-size: 1.1rem !important;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
<style lang="scss">
.border-red .vs__dropdown-toggle {
  border-color: red;
}

.vs__selected-options {
  max-height: 2rem;
  white-space: nowrap;
}

.vs__dropdown-toggle {
  height: 35px;
}

.info {
  height: 37px;
  font-size: 14px;
}

.info-box {
  margin-top: 7px
}
</style>
