<template>
    <div class="d-row container">
        <h4>Get report for selected year (R06)</h4>
        <div>
            <label>Year</label>
            <VueDatePicker v-model="state.date" year-picker :clearable="false">
            </VueDatePicker>
        </div>
        <div class="buttons">
            <button class="accent" @click="getReport">Get report</button>
        </div>
    </div>
    <LoadingDownloadScreen v-if="state.showLoading"></LoadingDownloadScreen>
</template>

<script setup lang="ts">
import { parseError } from "@/common/util";
import { useReportStore } from "@/stores/report";
import { showToastError } from "@/toastification";
import { reactive } from "vue";
import LoadingDownloadScreen from "@/components/util/LoadingDownloadScreen.vue";

const state = reactive({
    date: (new Date()).getFullYear(),
    showLoading: false,
});

const reportStore = useReportStore();

async function getReport() {
    state.showLoading = true;
    try {
        await reportStore.downloadR06(state.date);
    }
    catch (e) {
        console.error(e);
        showToastError("Report was unable to generate: " + parseError(e));
    }
    finally {
        state.showLoading = false;
    }
}

</script>

<style scoped lang="scss"></style>