export interface MonthlyApprovalItem {
    id: string;
    year: number;
    month: number;
    status: number;     
    comment: string;
    created: string;
    modified: string;
    userId: string;
    approverId: string;
}

export enum ApprovalStatus {
    Ongoing = 0, SentForApproval = 1, Approved = 2, Rejected = 3,
}