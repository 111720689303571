<template>
    <div>
        <div class="activity-assignment">
            <div>
                <div>
                    <label>Activity number</label>
                </div>
                <div>
                    <label>Description</label>
                </div>
            </div>
            <div v-for="(item, index) in props.definitions " :key="getKey('ac', index)">
                <div>
                    <input :key="getKey('an', index)" v-model="item.activityNumber" :disabled="!item.editable" type="string"
                        :class="{ 'wrong-activity-number': props.definitions.filter(a => a.activityNumber == item.activityNumber).length > 1 }" />
                </div>
                <div>
                    <input :key="getKey('ad', index)" v-model="item.description" :disabled="!item.editable" type="string" />
                </div>
                <DxButton icon="trash" @click="deleteDefinition(item, index)" />
                <div v-if="item.id && !state.canSave">
                    <DxButton v-if="!item.editable" icon="edit" @click="updateDefinition(item, index)" />
                    <DxButton v-else icon="save" @click="saveEdit(item, index)" />
                </div>
            </div>
        </div>
        <div class="buttons">
            <button @click="addDefinition" :class="{ accent: !state.canSave }"><b>+</b>Add activity</button>
            <button v-if="state.canSave" class="accent" @click="saveDefinitions()">Save</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { DefinitionItem } from "@/services/entities/DefinitionItem";
import { reactive } from "vue";
import { getKey } from "@/common/util";
import DxButton from 'devextreme-vue/button';
import { showToastError } from "@/toastification";

const emit = defineEmits(['save', 'delete', 'edit']);

const props = defineProps({
    definitions: { Type: [] as DefinitionItem[], default: [] as DefinitionItem[] },
});
const state = reactive({
    canSave: false
});

function addDefinition() {
    state.canSave = true;
    props.definitions.push({ editable: true });
}

function saveDefinitions() {
    // Check if there are any lines without ActivityNumberId and if there are any duplicates
    if (props.definitions.some(a => !a.activityNumber) || props.definitions.some(a => props.definitions.filter(b => b.activityNumber == a.activityNumber).length > 1)) {
        showToastError("Please input unique activity number for all activities and remove empty ones.");
        return;
    }    
    emit('save', props.definitions)
    state.canSave = false;
}

function saveEdit(item: DefinitionItem, index: number) {
    item.editable = !item.editable;
    emit('edit', item)
}

function updateDefinition(item: DefinitionItem, index: number) {
    item.editable = !item.editable;
}

function deleteDefinition(item: DefinitionItem, index: number) {
    if (item.id) {
        emit('delete', item.id)
    }
    else {
        props.definitions.splice(index, 1);
    }
}

</script>

<style scoped lang="scss">
//class for wrongly inputed activity number in input with red border
.wrong-activity-number {
    border: 1px solid red;
}

.activity-assignment {
    display: flex;
    flex-direction: column;
    width: 100%;

    &>div {
        display: flex;
        flex-direction: row;
        width: 450px;
        align-items: flex-end;

        &>div {
            width: 300px
        }

        :first-child {
            margin-right: 5px;
        }
    }
}
</style>