import { UserRole } from "@/services/entities/UserItem";

const reportHourPage = { id: 0, to: "/", name: "ReportHours", text: "Report hours", isActive: (route: any) => { return route.name == 'ReportHours' } };
const approvalPage = { id: 1, to: "/approvals", name: "Approvals", text: "Approvals", isActive: (route: any) => { return route.name == 'Approvals' } };
const projectPage = { id: 2, to: "/projects", name: "Projects", text: "Projects", isActive: (route: any) => { return route.name == 'Projects' } };
const userPage = { id: 3, to: "/users", name: "UserManagement", text: "User management", isActive: (route: any) => { return route.name == 'UserManagement' } };
const settingsPage = { id: 9, to: "/settings", name: "AppSettings", text: "Application settings", isActive: (route: any) => { return route.path.includes('settings') } };
const reportingPage = { id: 4, to: "/reporting", name: "Reporting", text: "Reporting", isActive: (route: any) => { return route.path.includes('reporting')  } };

export const pages = [
  { role: UserRole.None, pages: [] },
  { role: UserRole.Employee, pages: [reportHourPage] },
  { role: UserRole.EmployeeReport, pages: [reportHourPage, reportingPage] },
  { role: UserRole.TeamLeader, pages: [reportHourPage, approvalPage, projectPage, userPage] },
  { role: UserRole.TeamLeaderReport, pages: [reportHourPage, approvalPage, projectPage, userPage, reportingPage] },
  { role: UserRole.Administrator, pages: [reportHourPage, approvalPage, projectPage, userPage, reportingPage, settingsPage] },
  //{ role: UserPrivileges.Report, pages: [reportHourPage] }, -- tu příjde report page
]