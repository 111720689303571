<template>
    <VueFinalModal class="modal" content-class="modal-content" overlay-transition="vfm-fade" :clickToClose="false"
        @click-outside="beforeClose">
        <HolidayForm :value="props.value">
            <template #buttons>
                <div v-if="props.isCreate">
                    <button @click="props.value.push({ date: new Date() } as HolidayItem)">Add next</button>
                    <button class="accent" @click="create()">Save holidays</button>
                </div>
                <button v-else class="accent" @click="edit()">Save changes</button>
            </template>
        </HolidayForm>
        <ConfirmModal :modal-id="confirmModalId" text="You have unsaved changes, do you really want to leave this form?"
            confirm-text="Close form" @confirm="() => {
                vfm.close(confirmModalId);
                emit('close');
            }
                " @close="vfm.close(confirmModalId)">
        </ConfirmModal>
    </VueFinalModal>
</template>

<script setup lang="ts">
import { HolidayItem } from "@/services/entities/HolidayItem";
import { useSettingsStore } from "@/stores/setting";
import { useVfm, VueFinalModal } from "vue-final-modal";
import ConfirmModal from "./ConfirmModal.vue";
import HolidayForm from "@/components/holiday/HolidayForm.vue";
import { reactive } from "vue";

const vfm = useVfm();
const confirmModalId = Symbol("editConfirmModalId");
const settingsStore = useSettingsStore();
const emit = defineEmits(["create", "close", "edit"]);

const props = defineProps(['value', 'isCreate']);
function beforeClose() {
    vfm.open(confirmModalId);
}

function create() {
    emit("create");
}

function edit() {
    emit("edit");
}
</script>

<style scoped lang="scss"></style>