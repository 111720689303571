<template>
  <div>
    <h4>Locks for {{ props.userName }}</h4>
    <div class="table-detail" v-for="lock in locks" :key="lock.id">
      <div class="checkbox-label">
        <label :for="'range_' + lock.id">Interval: </label>
        <VueDatePicker v-model="lock.range" range :enable-time-picker="false" :teleport="true" :clearable="false"
          :id="'range_' + lock.id" utc="preserve" class="rangeRM stool"></VueDatePicker>
        <label :for="'lock_' + lock.id">Is locked: </label>
        <input :id="'lock_' + lock.id" type="checkbox" v-model="lock.isLocked" class="stool" />
      </div>
    </div>
    <div class="buttons">
      <button @click="addInterval()">+ Add interval</button>
      <button class="accent" @click="save">Save</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LockHistoryItem } from "@/services/entities/LockHistoryItem";
import { useReportingMonthStore } from "@/stores/reporting";
import DxButton from "devextreme-vue/button";
import { useLockItemsStore } from "@/stores/lock";
import TileCard from "@/components/util/TileCard.vue";
import { Authorization } from "@/services/authenticate";

const lockStore = useLockItemsStore();
const reportingStore = useReportingMonthStore();
const emit = defineEmits(["delete", "change", "close", "refreshUsers"]);

const props = defineProps({
  locks: { Type: [] as LockHistoryItem[], default: [] as LockHistoryItem[] },
  userName: { Type: {} as string, default: "Unknown" as string },
  userId: { Type: {} as string, default: "" as string },
  year: { Type: {} as number },
  month: { Type: {} as number },
});

function addInterval() {
  let newInterval = {
    dateFrom: new Date(),
    dateTo: new Date(),
    isLocked: false,
    isPlaceholder: false,
    lockedUserId: props.userId,
  } as LockHistoryItem;
  props.locks.push(newInterval);
}

async function save() {
  //   props.locks.array.forEach((a: any) => {
  //     a.dateFrom = new Date(Date.UTC(a.dateFrom.year, a.dateFrom.month, a.dateFrom.day));
  //     a.dateTo = new Date(Date.UTC(a.dateTo.year, a.dateTo.month, a.dateTo.day));
  //   });
  await lockStore.saveLockItems(props.locks, props.userId, Authorization.id);
  emit('refreshUsers');
  await reportingStore.loadReportingMonth(
    props.year,
    props.month,
    props.userId
  );
}
</script>

<style>
.checkbox-label {
  display: flex;
  align-items: center;
}

.checkbox-label input {
  margin-right: 5px;
}

.rangeRM {
  margin-right: 55px;
}

.stool {
  margin-bottom: 7px;
}
</style>