<template>
  <VueFinalModal class="modal" content-class="modal-content" overlay-transition="vfm-fade" :clickToClose="false"
    @click-outside="beforeClose">
    <ProjectForm :value="state.value">
      <template #header><span class="title">Create project</span></template>
      <template #buttons>
        <div class="buttons">
          <button @click="beforeClose">Cancel</button>
          <button class="accent" @click="create">Create project</button>
        </div>
      </template>
    </ProjectForm>
    <ConfirmModal :modal-id="confirmModalId" text="You have unsaved changes, do you really want to leave this form?"
      confirm-text="Close form" @confirm="() => {
        vfm.close(confirmModalId);
        emit('close');
      }
        " @close="vfm.close(confirmModalId)">
    </ConfirmModal>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { ProjectItem } from "@/services/entities/ProjectItem";
import { useProjectStore } from "@/stores/project";
import { reactive } from "vue";
import { useVfm, VueFinalModal } from "vue-final-modal";
import ConfirmModal from "./ConfirmModal.vue";
import ProjectForm from "../project/ProjectForm.vue";
import { showToastError } from "@/toastification";
import { formatText } from "@/common/util";

const vfm = useVfm();
const confirmModalId = Symbol("confirmModalId");
const projectStore = useProjectStore();
const emit = defineEmits(["close"]);

const state = reactive({
  value: {
    projectStart: new Date(),
    projectEnd: new Date(),
    projectType: 0,
    projectName: '',
    projectNumber: '',
    responsiblePerson: '',
    projectManager: '',
    nonBillableProjectType: 0,
    serviceId: null,
  } as any as ProjectItem,
});

const mandatoryFields = [
  {
    key: 0, values: [
      "projectName", "projectNumber", "responsiblePerson", "projectManager", "serviceId", "invoiceTypeId", "projectStart", "projectEnd"
    ]
  }, {
    key: 1, values: [
      "projectName", "projectNumber", "responsiblePerson", "projectManager", "projectStart", "projectEnd"
    ]
  }, {
    key: 2, values: [
      "projectName", "projectNumber", "responsiblePerson", "projectManager", "projectStart", "projectEnd"
    ]
  },
];

async function create() {
  let canCreate = true;
  if (state.value.projectStart > state.value.projectEnd) {
    showToastError("Project start date must be before project end date");
    canCreate = false;
  }
  state.value.projectStart = new Date(Date.UTC(state.value.projectStartPicker.year, state.value.projectStartPicker.month));
  state.value.projectEnd = new Date(Date.UTC(state.value.projectEndPicker.year, state.value.projectEndPicker.month + 1, 0));
  if (state.value.projectTypeId >= 0) {
    mandatoryFields.find(a => a.key == state.value.projectTypeId)?.values.forEach(element => {
      const value = state.value[element];
      if (value == null || value.length == 0) {
        showToastError(`Missing value: ${formatText(element)}`);
        canCreate = false;
      }
    });
    if (canCreate) {
      const projectId = await projectStore.createProject(state.value);
      emit("close", projectId);
    }
  } else {
    showToastError("Select project type")
  }
}

function beforeClose() {
  vfm.open(confirmModalId);
}
</script>

<style scoped lang="scss">
.d-row {
  flex-wrap: wrap;

  &>div {
    min-width: 210px;
  }
}

.d-row {
  margin-top: 7px;
}
</style>