import { defineStore } from "pinia";
import { ServiceItem } from "@/services/entities/ServiceItem";
import { ProjectType } from "@/services/entities/ProjectItem";
import { UserItem, UserRole } from "@/services/entities/UserItem";
import { getServerUrl } from "@/common/urls";
import UserDataFetcher from "@/services/connectors/userConnector";
import MonthlyApprovalDataFetcher from "@/services/connectors/approvalConnector";
import { UserApprovalModel } from "@/services/entities/Approval/UserApprovalModel";
import {
  closeToast,
  showLoading,
  showSuccess,
  showToastError,
} from "@/toastification";
import { parseError } from "@/common/util";
import { UserProjectShowItem } from "@/services/entities/UserProjectItem";

const users = new UserDataFetcher(getServerUrl());
const approvalUsers = new MonthlyApprovalDataFetcher(getServerUrl());

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {} as UserItem,
    users: {
      data: [] as UserItem[],
      loader: false,
    },
    usersForTL: {
      data: [] as UserItem[],
      loader: false,
    },
    userApprovalModel: {
      data: {} as UserApprovalModel,
      loader: false,
    },
    teamLeaders: {
      data: [] as UserItem[],
      loader: false,
    },
    userProjects: {
      data: [] as UserProjectShowItem[],
      loader: false,
    },
    loggedUser: {} as UserItem,
}),
  actions: {
    async loadUser(id: string): Promise<void> {
      try {
        this.user = await users.get(id);
      } catch (e: any) {
        console.log(e);
        showToastError("Failed to load user: " + parseError(e));
      }
    },
    async loadAll(): Promise<void> {
      try {
        this.users.loader = true;
        this.users.data = await users.getAll();
        //await this.loadTeamLeaders();
        this.users.data?.forEach((a) => {
          a.rates?.forEach((b) => {
            b.range = [b.from, b.to];
            if (new Date(b.from) < new Date() && new Date(b.to) > new Date()) {
              a.currentRate = b;
            }
            b.monthRange = [] as any;
            b.monthRange[0] = {
              month: new Date(b.from).getMonth(),
              year: new Date(b.from).getFullYear(),
            };
            b.monthRange[1] = {
              month: new Date(b.to).getMonth(),
              year: new Date(b.to).getFullYear(),
            };
          });
          a.dailyCommitments?.forEach((b) => {
            b.yearMonth = { month: b.month, year: b.year} as YearMonth;
          });
          a.userRole = UserRole[a.role];
        });
      } catch (e: any) {
        console.log(e);
        showToastError("Failed to load users: " + parseError(e));
      } finally {
        this.users.loader = false;
      }
    },
    async loadForManager(id: string): Promise<void> {
      try {
        this.usersForTL.loader = true;
        this.usersForTL.data = await users.getForTeamLeader(id);
        this.usersForTL.data.forEach((a) => {
          a.rates.forEach((b) => {
            b.range = [b.from, b.to];
          });
          a.userRole = UserRole[a.role];
        });
      } catch (e: any) {
        console.log(e);
        showToastError("Failed to load users: " + parseError(e));
      } finally {
        this.usersForTL.loader = false;
      }
    },
    async loadApproval(year: number, month: number, id: string): Promise<void> {
      try {
        this.userApprovalModel.loader = true;
        this.userApprovalModel.data = await approvalUsers.getUsersForApproval(
          year,
          month,
          id
        );
      } catch (e: any) {
        console.log(e);
      } finally {
        this.userApprovalModel.loader = false;
      }
    },
    async create(user: UserItem): Promise<void> {
      try {
        const toast = showLoading("Creating new user");
        user.dailyCommitments.forEach((a) => {
          a.year = a.yearMonth.year;
          a.month = a.yearMonth.month;
        });
        await users.create(user);
        closeToast(toast);
        showSuccess("User created");
        this.loadAll();
      } catch (e: any) {
        showToastError("Failed to create user: " + parseError(e));
        console.log(e);
      }
    },
    async loadProjectsForUser(userId: string): Promise<void> {
      try {
        this.userProjects.loader = true;
        if (!userId) {
          this.userProjects.data = [];
          this.userProjects.loader = false;
          return;
        }
        const result = await users.getProjectsForUser(userId) as UserProjectShowItem[];
        result.forEach((a) => {
          a.project.projectType = ProjectType[a.project.projectTypeId];
          const lastPart = a.participations.pop();
          a.participationFrom = lastPart?.from ? new Date(lastPart?.from) : null;
          a.participationTo = lastPart?.to ? new Date(lastPart?.to) : null;
        });
        this.userProjects.data = result;
        this.userProjects.loader = false;
      } catch (e: any) {
        showToastError("Failed to load user projects: " + parseError(e));
        console.log(e);
      }
    },
    async update(user: UserItem): Promise<void> {
      try {
        const toast = showLoading("Updating user");
        user.dailyCommitments.forEach((a) => {
          a.year = a.yearMonth.year;
          a.month = a.yearMonth.month;
        });
        await users.update(user);
        closeToast(toast);
        showSuccess("User updated");
        await this.loadAll();
        await this.loadTeamLeaders();
      } catch (e: any) {
        showToastError("Failed to update user: " + parseError(e));
        console.log(e);
      }
    },
    async deleteUserRate(id: string): Promise<void> {
      try {
        const toast = showLoading("Deleting user rate");
        await users.deleteUserRate(id);
        closeToast(toast);
        showSuccess("User rate deleted");
        await this.loadAll();
      } catch (e: any) {
        showToastError("Failed to delete user rate: " + parseError(e));
        console.log(e);
      }
    },
    async deleteDailyCommitment(id: string): Promise<void> {
      try {
        const toast = showLoading("Deleting user daily commitment");
        await users.deleteDailyCommitment(id);
        closeToast(toast);
        showSuccess("User daily commitment deleted");
        await this.loadAll();
      } catch (e: any) {
        showToastError("Failed to delete user daily commitment: " + parseError(e));
        console.log(e);
      }
    },
    async deleteVacation(id: string): Promise<void> {
      try {
        const toast = showLoading("Deleting user rate");
        await users.deleteVacation(id);
        closeToast(toast);
        showSuccess("User vacation deleted");
        await this.loadAll();
      } catch (e: any) {
        showToastError("Failed to delete user vacation: " + parseError(e));
        console.log(e);
      }
    },
    async deleteFloatingDay(id: string): Promise<void> {
      try {
        const toast = showLoading("Deleting user rate");
        await users.deleteFloatingDay(id);
        closeToast(toast);
        showSuccess("User floating days deleted");
        await this.loadAll();
      } catch (e: any) {
        showToastError("Failed to delete user floating days: " + parseError(e));
        console.log(e);
      }
    },
    async deleteOtFond(id: string): Promise<void> {
      try {
        const toast = showLoading("Deleting overtime correction");
        await users.deleteOtFond(id);
        closeToast(toast);
        showSuccess("Overtime correction deleted");
        await this.loadAll();
      } catch (e: any) {
        showToastError("Failed to delete overtime correction: " + parseError(e));
        console.log(e);
      }
    },
    async loadUserRole(): Promise<void> {
      try {
        let result = await users.getLoggedUserInfo();
        result.role = result.isAdmin
          ? (UserRole.Administrator as number)
          : result.role;
        if (result.role != UserRole.Administrator && result.isReport) {
          result.role =
            result.role == UserRole.Employee
              ? UserRole.EmployeeReport
              : UserRole.TeamLeaderReport;
        }
        this.loggedUser = result;
        sessionStorage.setItem("role", String(result.role));
      } catch (e: any) {
        showToastError("Failed to load user role: " + parseError(e));
        console.log(e);
      }
    },
    async loadTeamLeaders(): Promise<void> {
      try {
        this.teamLeaders.loader = true;
        this.teamLeaders.data = await users.getTeamLeaders();
        this.teamLeaders.loader = true;
      } catch (e: any) {
        showToastError("Failed to load team leaders: " + parseError(e));
        console.log(e);
      }
    },
  },
  getters: {
    getUser(): UserItem {
      return this.user;
    },
    getAll(): UserItem[] {
      return this.users.data;
    },
    getAllLoaded(): boolean {
      return !this.users.loader;
    },
    getUsersForTL(): UserItem[] {
      return this.usersForTL.data;
    },
    getUsersForTLLoaded(): boolean {
      return !this.usersForTL.loader;
    },
    getTeamLeaders(): UserItem[] {
      return this.teamLeaders.data;
    },
    getTeamLeadersLoaded(): boolean {
      return !this.teamLeaders.loader;
    },
    getLoggedUser(): UserItem {
      return this.loggedUser;
    },
    getUserRole(): UserRole {
      return this.loggedUser.role;
    },
    getForApproval(): UserApprovalModel {
      return this.userApprovalModel.data;
    },
    getForApprovalLoaded(): boolean {
      return !this.userApprovalModel.loader;
    },
    getUserProjects(): UserProjectShowItem[] {
      return this.userProjects.data;
    },
    getUserProjectsLoaded(): boolean {
      return !this.userProjects.loader;
    },
  },
});
