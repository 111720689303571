import ApiConnector from "../apiConnector";

class ReportDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor(baseURL: string) {
    this.apiConnector = new ApiConnector(baseURL);
  }

  public async getR06(year: number): Promise<any> {
    return await this.apiConnector.getBlob<any>("R06/" + year);
  }

  public async getMonthly(year: number, month: number, weekly: boolean, daily: boolean) {
    return await this.apiConnector.getBlob<any>(`Monthly/${year}/${month}/${weekly}/${daily}`);
  }
  //Mi codigo
  public async getSap(year: number, month: number, weekly: boolean) {
    return await this.apiConnector.getBlob<any>(`SAP/${year}/${month}/${weekly}`);
  }

//aqui acaba Mi codigo

  public async getDataForInvoice(serviceId: number, year: number, month: number): Promise<any> {
    return await this.apiConnector.get<any>(`invoice/${serviceId}/${year}/${month}`);
  }

  public async getMonhtlyUser(year: number, month: number, userId: string): Promise<any> {
    return await this.apiConnector.getBlob<any>(`monthlyUser/${year}/${month}/${userId}`);
  }
}

export default ReportDataFetcher;
