<template>
  <div class="container">
    <h4>Mandatory projects</h4>
    <span>Check and assign any missing mandatory projects for all users</span>
    <div class="buttons">
      <button @click="assignMandatory" class="accent"
        title="If some user is missing any of the mandatory projects, click this button">Check & Assign</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSupportStore } from "@/stores/supoort"
import { Authorization } from "@/services/authenticate";

const support = useSupportStore();

async function assignMandatory() {
  await support.assingMandatoryProjects(Authorization.id);
}

</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  max-width: 25rem;
}
</style>
