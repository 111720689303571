<template>
    <VueFinalModal class="modal" content-class="modal-content" overlay-transition="vfm-fade" :clickToClose="false"
        @click-outside="beforeClose">
        <ProjectForm :value="props.value" :edit="true">
            <template #header><span class="title">Edit project</span></template>
            <template #buttons>
                <div class="buttons">
                    <button @click="beforeClose">Cancel</button>
                    <button class="accent" @click="update">Save changes</button>
                </div>
            </template>
        </ProjectForm>
        <ConfirmModal :modal-id="confirmModalId" text="You have unsaved changes, do you really want to leave this form?"
            confirm-text="Close form" @confirm="() => {
                vfm.close(confirmModalId);
                emit('close');
            }
                " @close="vfm.close(confirmModalId)">
        </ConfirmModal>
    </VueFinalModal>
</template>
  
<script setup lang="ts">
import { ProjectItem } from "@/services/entities/ProjectItem";
import { useProjectStore } from "@/stores/project";
import { reactive } from "vue";
import { useVfm, VueFinalModal } from "vue-final-modal";
import ConfirmModal from "./ConfirmModal.vue";
import ProjectForm from "../project/ProjectForm.vue";
import { formatText } from "@/common/util";
import { showToastError } from "@/toastification";

const vfm = useVfm();
const confirmModalId = Symbol("confirmModalId");
const projectStore = useProjectStore();
const emit = defineEmits(["close"]);

const props = defineProps({
    value: { Type: {} as ProjectItem, default: {} as ProjectItem }
});

const mandatoryFields = [
    {
        key: 0, values: [
            "projectName", "projectNumber", "responsiblePerson", "projectManager", "serviceId", "invoiceTypeId"
        ]
    }, {
        key: 1, values: [
            "projectName", "projectNumber", "responsiblePerson", "projectManager",
        ]
    }, {
        key: 2, values: [
            "projectName", "projectNumber", "responsiblePerson", "projectManager",
        ]
    },
];

async function update() {

    let canUpdate = true;
    if (props.value.projectStart > props.value.projectEnd) {
        showToastError("Project start date must be before project end date");
        canUpdate = false;
    }
    props.value.projectStart = new Date(Date.UTC(props.value.projectStartPicker.year, props.value.projectStartPicker.month));
    props.value.projectEnd = new Date(Date.UTC(props.value.projectEndPicker.year, props.value.projectEndPicker.month + 1, 0));
    if (props.value.projectTypeId >= 0) {
        mandatoryFields.find(a => a.key == props.value.projectTypeId)?.values.forEach(element => {
            const value = props.value[element];
            if (value == null || value.length == 0) {
                showToastError(`Missing value: ${formatText(element)}`);
                canUpdate = false;
            }
        });
        if (canUpdate) {
            await projectStore.updateProject(props.value);
            emit("close");
        }
    } else {
        showToastError("Select project type")
    }
}

function beforeClose() {
    vfm.open(confirmModalId);
}
</script>
  
<style scoped lang="scss">
.d-row {
    margin-top: 7px;
}
</style>