<template>
    <div class="container">
        <h4>Get SAP report for selected month {{ weeklyReportText }}</h4>
        <div class="d-row-3">
            <div>
                <label>Monthly</label>
                <VueDatePicker v-model="state.dateMonth" month-picker :clearable="false">
                </VueDatePicker>
            </div>
            <div class="d-checkbox">
                <label for="weekly">Weekly</label>
                <input type="checkbox" name="weekly1" id="weekly1" v-model="state.weeklyOptions.weekly">
            </div>
            <div class="buttons">
                <button class="accent" @click="getWeeklyReport">Get report</button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { parseError } from "@/common/util";
import { useReportStore } from "@/stores/report";
import { showToastError } from "@/toastification";
import { computed, reactive } from "vue";

const state = reactive({
    //LUIS ANGEL
    weeklyOptions: { weekly: false },
    dateMonth: { year: (new Date).getFullYear(), month: (new Date).getMonth() },
    showLoading: false
});

const reportStore = useReportStore();
// Mi prueba de codigo
const weeklyReportText = computed(() => {
    let result = ""
    if (state.weeklyOptions.weekly) {
        result = "with weekly "
    }
    return result
})
// Aqui termina mi codigo


//Mi funcion prueba
async function getWeeklyReport() {
    state.showLoading = true;
    try {
        await reportStore.downloadSap(state.dateMonth.year, state.dateMonth.month + 1, state.weeklyOptions.weekly)
    }

    catch (e) {
        console.error(e);
        showToastError("Report was unable to generate: " + parseError(e));
    }
    finally {
        state.showLoading = false;
    }
}

</script>

<style scoped lang="scss">
.d-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>