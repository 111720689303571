export function getKey(name: string, index: number) {
  return `${name}_${index}`;
}

export function parseError(e: any) {
  if (e.title) {
    return e.title;
  } else if (e.Message) {
    return e.Message;
  } else {
    return e;
  }
}

export function formatText(text: string): string {
  // Check if the string ends with "id" in any case
  if (text.match(/id$/i)) {
    // Remove the "id" suffix
    text = text.slice(0, -2);
  }

  // Use regex to find the position of the capital letter
  const capitalLetterIndex: number = text.search(/[A-Z]/);

  // If capital letter is found
  if (capitalLetterIndex !== -1) {
    // Insert space before the capital letter and convert it to lowercase
    const formattedText: string =
      text.slice(0, capitalLetterIndex) +
      " " +
      text.charAt(capitalLetterIndex).toLowerCase() +
      text.slice(capitalLetterIndex + 1);

    // Capitalize the first letter
    const finalText: string =
      formattedText.charAt(0).toUpperCase() + formattedText.slice(1);

    return finalText;
  }

  return text; // Return original text if no capital letter is found
}

export function download(file: Blob, filename: string) {
  const nav = window.navigator as any;
  if (nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(file, filename);
  } else {
    // Others
    const a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export function downloadExcelFromApi(data: any, name: string) {
  try {
    // Create a Blob from the provided data
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);

    // Set the file name
    downloadLink.download = name;

    // Append the link to the body and trigger the click event
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Cleanup by removing the link from the DOM
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading Excel file:", error);
  }
}

export const loadingTexts = [
  "Hold on tight!",
  "Optimizing quantum flux",
  "Just a moment...",
  "Calibrating the hyperdrive",
  "Counting to infinity...",
  "Initiating warp speed",
  "Finding the meaning of life",
  "Unleashing the magic",
  "Reticulating splines",
  "Hatching dragons",
  "Conquering the digital realm",
  "Capturing unicorns",
  "Decrypting secret messages",
  "Dancing electrons",
  "Journeying through the cosmos",
  "Transcending reality",
  "Evolving into greatness",
  "Crafting pixels",
  "Unraveling mysteries",
  "Syncing with the matrix",
  "Summoning aliens",
  "Bending time and space",
  "Uploading awesomeness",
  "Harnessing the power of stars",
  "Building castles in the cloud",
  "Unplugging reality",
  "Syncing with parallel universes",
  "Spinning up the engines",
  "Generating good vibes",
  "Cooking up some code",
  "Dreaming in binary",
  "Crunching the numbers",
  "Waking up the machines",
  "Sailing through the digital sea",
  "Unlocking hidden potentials",
  "Tuning the frequency",
  "Merging with the void",
  "Connecting the dots",
  "Awakening the kraken",
  "Poking the bits",
  "Weaving the web",
  "Debugging the universe",
  "Defying gravity",
  "Shuffling data",
  "Breaking the time barrier",
  "Firing up the engines",
  "Harvesting stardust",
  "Navigating the celestial spheres",
  "Chasing after comets",
  "Polishing the pixels",
  "Harmonizing with the cosmos",
  "Quantum leap in progress",
  "Assembling the future",
  "Swimming in the binary ocean",
  "Mastering the art of patience",
  "Piloting through the code clouds",
  "Playing hide and seek with photons",
  "Deciphering cosmic riddles",
  "Charging the energy crystals",
  "Surfing the digital wave",
  "Sculpting reality",
  "Summoning the code spirits",
  "Solving the enigma",
  "Evoking the spirit of innovation",
  "Taming wild algorithms",
  "Crushing bugs",
  "Diving into the unknown",
  "Conducting the symphony of progress",
  "Navigating the data stream",
  "Shaping the future",
  "Quantum tunneling in progress",
  "Embracing the chaos",
  "Synchronizing with the heartbeat of the universe",
  "Stepping into the beyond",
  "Weaving dreams into lines of code",
  "Transmitting positive vibes",
  "Experiencing technical turbulence",
  "Unraveling the cosmic tapestry",
  "Crafting the tapestry of time",
  "Syncing with celestial rhythms",
  "Dancing through the algorithmic rain",
  "Embracing the singularity",
  "Experiencing quantum entanglement",
  "Uploading dreams to the cloud",
  "Navigating the code labyrinth",
  "Harvesting digital crops",
  "Sculpting the fabric of existence",
  "Synchronizing with the heartbeat of creation",
  "Launching into the code frontier",
  "Surfing the information superhighway",
  "Piloting through the code nebulae",
  "Quantum dancing with photons",
  "Harvesting cosmic energy",
  "Bouncing off the event horizon",
  "Transcending the limitations of reality",
  "Uploading the essence of imagination",
  "Diving into the sea of possibilities",
  "Riding the wave of innovation",
  "Hacking the space-time continuum",
  "Infusing the universe with creativity",
  "Forging a path through the binary forest",
  "Loading screens are boring",
];
