<template>
    <DxDataGrid :hover-state-enabled="true" :show-borders="false" :data-source="props.data" key-expr="id" id="user-management-table"
        :selected-row-keys="selectedKeys" :auto-navigate-to-focused-row="true">
        <DxFilterRow :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxStateStoring :enabled="true" type="localStorage" storage-key="user-management-table" />
        <DxColumn data-field="id" data-type="string" caption="Id" :visible="false" />
        <DxColumn data-field="fullName" data-type="string" caption="Name" />
        <DxColumn data-field="userRole" data-type="string" caption="Role" />
        <DxColumn data-field="teamLeader.email" data-type="string" caption="Team leader" />
        <DxColumn data-field="currentRate.rate.name" data-type="string" caption="Current rate" />
        <DxColumn data-field="isDeleted" data-type="boolean" caption="Deleted" />
        <DxColumn type="buttons" caption="Actions" :width="70">
            <!-- <DxButton hint="Hide detail" icon="eyeclose" styling-mode="contained" type="normal" @click="clearDetail" /> -->
            <DxButton hint="View detail" icon="eyeopen" styling-mode="contained" type="normal" @click="showDetail" />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager :show-page-size-selector="true" :allowed-page-sizes="[5, 10, 20]" />
    </DxDataGrid>
</template>
  
<script setup lang="ts">
import DxDataGrid, {
    DxColumn,
    DxFilterRow,
    DxStateStoring,
    DxPaging,
    DxHeaderFilter, DxPager,
    DxButton
} from "devextreme-vue/data-grid";
import { UserItem, UserRole } from "@/services/entities/UserItem";
import { ref } from "vue";

const emit = defineEmits(["detail"]);
const selectedKeys = ref([] as number[]);

const props = defineProps({
    data: { Type: [] as UserItem[], default: [] as UserItem[] }
});

function showDetail(e: any) {
    selectedKeys.value = [e.row.data.id];
    emit("detail", e.row.data.id);
}

function clearDetail(e: any) {
    emit("detail", null);
}

</script>
  
<style scoped lang="scss"></style>
  