import ApiConnector from "../apiConnector";
import { DefinitionItem } from "../entities/DefinitionItem";
import { ProjectItem } from "../entities/ProjectItem";
import { UserProjectItem } from "../entities/UserProjectItem";

class ProjectDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor(baseURL: string) {
    this.apiConnector = new ApiConnector(baseURL);
  }

  public async getAll(): Promise<any[]> {
    return await this.apiConnector.get<any[]>("project/all");
  }

  public async updateData(id: string, project: ProjectItem) {
    return await this.apiConnector.put<string>("project/" + id, project);
  }

  public async updateUsers(id: string, users: UserProjectItem[]) {
    return await this.apiConnector.put<string>(`project/${id}/users`, users);
  }

  public async updateDefinitions(id: string, definitions: DefinitionItem[]) {
    return await this.apiConnector.put<string>(
      `project/${id}/definitions`,
      definitions
    );
  }

  public async updateDefinition(id: string, definition: DefinitionItem) {
    return await this.apiConnector.put<string>(
      `project/${id}/definitions/${definition.id}`,
      definition
    );
  }

  public async deleteDefinition(id: string, definitionId: string) {
    return await this.apiConnector.delete<string>(
      `project/${id}/definitions/${definitionId}`
    );
  }

  public async create(project: ProjectItem) {
    return await this.apiConnector.post<string>("project", project);
  }

  public async update(project: ProjectItem) {
    return await this.apiConnector.put<void>("project", project);
  }
  public async delete(id: string) {
    return await this.apiConnector.delete<void>(`project/${id}`);
  }
}

export default ProjectDataFetcher;
