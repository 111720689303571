<template>
    <VueFinalModal class="modal" content-class="modal-content" overlay-transition="vfm-fade" :clickToClose="false"
        @click-outside="beforeClose">
        <ServiceForm :value="props.value">
            <template #header><span class="title">Edit service</span></template>
            <template #buttons>
                <button @click="beforeClose">Cancel</button>
                <button class="accent" @click="edit">Save service</button>
            </template>
        </ServiceForm>
        <ConfirmModal :modal-id="confirmModalId" text="You have unsaved changes, do you really want to leave this form?"
            confirm-text="Close form" @confirm="() => {
                vfm.close(confirmModalId);
                emit('close');
            }
                " @close="vfm.close(confirmModalId)">
        </ConfirmModal>
    </VueFinalModal>
</template>
  
<script setup lang="ts">
import { ServiceItem } from "@/services/entities/ServiceItem";
import { useVfm, VueFinalModal } from "vue-final-modal";
import ConfirmModal from "./ConfirmModal.vue";
import { useSettingsStore } from "@/stores/setting";
import ServiceForm from "@/components/services/ServiceForm.vue";
import { showToastError } from "@/toastification";
import { formatText } from "@/common/util";

const vfm = useVfm();
const confirmModalId = Symbol("editConfirmModalId");
const settingsStore = useSettingsStore();
const emit = defineEmits(["close"]);

const props = defineProps({
    value: { Type: {} as ServiceItem, default: {} as ServiceItem }
});

const mandatoryFields = ["blServiceName", "blpgCode", "poNumber", "mxSalesOrder", "invoiceAddress", "sapNumber", "operationsManager", "orderValue", "divisionId", "countryId", "validFrom", "validTo"];

async function edit() {
  let canCreate = true;
  mandatoryFields.forEach(element => {
    const value = props.value[element];
    if (value == null || value.length == 0) {
      showToastError(`Missing value: ${formatText(element)}`);
      canCreate = false;
    }
  });
  if (canCreate) {
    await settingsStore.updateService(props.value);
    emit("close");
  }
}

function beforeClose() {
    vfm.open(confirmModalId);
}
</script>
  
<style scoped lang="scss"></style>