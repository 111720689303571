<template>
    <div class="user-detail-body">
        <div class="title">
            <span v-if="!isCreate">User detail</span>
            <span v-else>Create user</span>
        </div>
        <div>
            <div class="user-inp">
                <div>
                    <label>Email</label>
                    <vue-select label="mail" :options="azureStore.getGroupMembers" :clearable="false"
                        v-model="props.user.email" @option:selected="(a) => selectedUser(a)" @search="handleSearch"
                        :disabled="!state.edit" />
                </div>
                <div>
                    <label>Team leader</label>
                    <vue-select label="email" :options="userStore.getTeamLeaders" :clearable="false"
                        v-model="props.user.teamLeaderId" :reduce="(item) => item.id" :disabled="!state.edit" />
                </div>
                <div>
                    <label>Given name</label>
                    <input type="string" v-model="props.user.firstName" :disabled="!state.edit" />
                </div>
                <div>
                    <label>Surname</label>
                    <input type="string" v-model="props.user.lastName" :disabled="!state.edit" />
                </div>
                <div>
                    <label>Employee ID</label>
                    <input type="number" v-model="props.user.employeeId" :disabled="!state.edit" />
                </div>
                <div>
                    <label>Cost center</label>
                    <input type="string" v-model="props.user.costCenter" :disabled="!state.edit" />
                </div>
                <div>
                    <label>Start date</label>
                    <VueDatePicker :clearable="false" :teleport="true" :enable-time-picker="false"
                        :disabled="!state.edit" v-model="props.user.startDate">
                    </VueDatePicker>
                </div>
            </div>
            <div class="usr-selector">
                <span>Active</span>
                <div class="user-row">
                    <input type="radio" id="r-yes" name="active"
                        :checked="props.user.isDeleted != null && !props.user.isDeleted"
                        @click="() => props.user.isDeleted = false" :disabled="!state.edit" />
                    <label for="r-yes">Yes</label>
                    <input type="radio" id="r-no" name="active"
                        :checked="props.user.isDeleted != null && props.user.isDeleted"
                        @click="() => props.user.isDeleted = true" :disabled="!state.edit" />
                    <label for="r-no">No</label>
                </div>
            </div>
            <div class="usr-selector container">
                <h4>Roles</h4>
                <div class="user-row">
                    <input type="radio" id="r-emp" name="roles"
                        :checked="props.user.role != null && props.user.role == 1" @click="props.user.role = 1"
                        :disabled="!state.edit" />
                    <label for="r-emp">Employee</label>
                    <input type="radio" id="r-leader" name="roles"
                        :checked="props.user.role != null && props.user.role == 2" @click="props.user.role = 2"
                        :disabled="!state.edit" />
                    <label for="r-leader">Team leader</label>
                    <div class="user-column">
                        <div>
                            <input type="checkbox" id="r-admin" v-model="props.user.isAdmin" :disabled="!state.edit">
                            <label for="r-admin">Admin</label>
                        </div>
                        <div>
                            <input type="checkbox" id="r-report" v-model="props.user.isReport" :disabled="!state.edit">
                            <label for="r-report">Report</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container user-rates">
                <h4>Rates</h4>
                <div class="user-row" v-for="(item, index) in props.user.rates" :key="getKey('us-rates', index)">
                    <vue-select :clearable="false" :appendToBody="true" :options="settingsStore.getRates" label="name"
                        :disabled="!state.edit" :reduce="(item) => item.id" v-model="item.rateId"></vue-select>
                    <VueDatePicker :clearable="false" :teleport="true" range :enable-time-picker="false"
                        :disabled="!state.edit" month-picker v-model="item.monthRange">
                    </VueDatePicker>
                    <div class="user-actions" v-if="state.edit || isCreate">
                        <DxButton icon="trash" @click="deleteRate(item, index)" />
                    </div>
                </div>
                <div class="buttons"><button v-if="state.edit" @click="addRate()"><b>+</b>Add</button></div>
            </div>
            <div class="container">
                <h4>Daily commitment</h4>
                <div class="user-row" v-for="(item, index) in props.user.dailyCommitments"
                    :key="getKey('us-commitment', index)">
                    <input type="number" v-model="item.hours" :disabled="!state.edit" @input="checkInput(index)">
                    <div>
                        <VueDatePicker :clearable="false" :teleport="true" :enable-time-picker="false" month-picker
                            :disabled="!state.edit" placeholder="From" v-model="item.yearMonth">
                        </VueDatePicker>
                    </div>
                    <div class="user-actions" v-if="state.edit || isCreate">
                        <DxButton icon="trash" @click="deleteDailyCommitment(item, index)" />
                    </div>
                </div>
                <div class="buttons"><button v-if="state.edit" @click="addDailyCommitment()"><b>+</b>Add</button></div>
            </div>
            <div class="container">
                <h4>Vacation</h4>
                <div class="user-row" v-for="(item, index) in props.user.flexiVacations"
                    :key="getKey('us-vacation', index)">
                    <input type="number" v-model="item.amount" :disabled="!state.edit">
                    <VueDatePicker :clearable="false" :teleport="true" :enable-time-picker="false"
                        :disabled="!state.edit" year-picker v-model="item.year">
                    </VueDatePicker>
                    <div class="user-actions" v-if="state.edit || isCreate">
                        <DxButton icon="trash" @click="deleteVacation(item, index)" />
                    </div>
                </div>
                <div class="buttons"><button v-if="state.edit" @click="addVacation()"><b>+</b>Add</button></div>
            </div>
            <div class="container">
                <h4>Floating days</h4>
                <div class="user-row" v-for="(item, index) in props.user.floatingDays"
                    :key="getKey('us-vacation', index)">
                    <input type="number" v-model="item.amount" :disabled="!state.edit">
                    <VueDatePicker :clearable="false" :teleport="true" :enable-time-picker="false"
                        :disabled="!state.edit" year-picker v-model="item.year">
                    </VueDatePicker>
                    <div class="user-actions" v-if="state.edit || isCreate">
                        <DxButton icon="trash" @click="deleteFloatingDay(item, index)" />
                    </div>
                </div>
                <div class="buttons"><button v-if="state.edit" @click="addFloatingDays()"><b>+</b>Add</button></div>
            </div>
            <div class="container">
                <h4>Overtime correction</h4>
                <div class="user-row" v-for="(item, index) in props.user.overtimeFonds"
                    :key="getKey('us-otFond', index)">
                    <input type="number" v-model="item.amount" :disabled="!state.edit">
                    <VueDatePicker :clearable="false" :teleport="true" :enable-time-picker="false"
                        :disabled="!state.edit" year-picker v-model="item.year">
                    </VueDatePicker>
                    <div class="user-actions" v-if="state.edit || isCreate">
                        <DxButton icon="trash" @click="deleteOtCorrection(item, index)" />
                    </div>
                </div>
                <div class="buttons"><button v-if="state.edit" @click="addOtCorrection()"><b>+</b>Add</button></div>
            </div>
            <div class="buttons">
                <button v-if="props.user.created" @click="showProjects">Show projects</button>
                <button v-if="!props.user.created" class="accent" @click.once="createUser()">Create user</button>
                <button v-else-if="!state.edit" @click="state.edit = !state.edit">Edit</button>
                <button v-else class="accent" @click.once="updateUser()">Save changes</button>
            </div>
        </div>
    </div>
    <ConfirmModal :modal-id="confirmModalId"
        text="Do you really want to remove this rate? It might negativly affect reports and other functionality."
        confirm-text="Delete user rate"
        @confirm="emit('deleteUserRate', state.deleteUserRateId); vfm.close(confirmModalId)"
        @close="vfm.close(confirmModalId)">
    </ConfirmModal>
    <UserProjectsModal :modal-id="userProjectId" :user-id="props.user.id"></UserProjectsModal>
</template>

<script setup lang="ts">
import { UserItem } from "@/services/entities/UserItem";
import { useAzureStore } from "@/stores/azure";
import { useSettingsStore } from "@/stores/setting";
import { computed, onMounted, reactive, watch } from "vue";
import VueSelect from "vue-select";
import { getKey } from "@/common/util";
import { UserRateItem } from "@/services/entities/UserRateItem";
import DxButton from 'devextreme-vue/button';
import { useVfm } from "vue-final-modal";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import { useUserStore } from "@/stores/user";
import UserProjectsModal from "../modals/UserProjectsModal.vue";
import { VacationItem } from "@/services/entities/Vacation";
import { OvertimeFondItem } from "@/services/entities/OvertimeFondItem";
import { ref } from "vue";
import { showToastError } from "@/toastification";

const azureStore = useAzureStore();
const settingsStore = useSettingsStore();
const userStore = useUserStore();
const vfm = useVfm();
const confirmModalId = Symbol("confirmModalId");
const userProjectId = Symbol("userProjectId");

const props = defineProps({
    user: { Type: {} as UserItem, default: {} as UserItem },
});

const isCreate = computed(() => {
    return !props.user.created;
});

watch(() => props.user.id, async (newUser, OldUser) => {
    state.edit = props.user.created == null;
    if (props.user.id) {
        await loadManager(props.user.id);
    }
});
const state = reactive({
    edit: props.user.created == null,
    deleteUserRateId: ""
});

onMounted(async () => {
    await settingsStore.loadRates();
});

const emit = defineEmits(['create', 'update', "deleteUserRate", "search"]);

function debounce(fn: (...args: any[]) => void, wait: number) {
    const timer = ref<number | undefined>();

    return function (this: any, ...args: any[]) {
        if (timer.value) {
            clearTimeout(timer.value); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer.value = window.setTimeout(() => {
            fn.apply(context, args); // call the function when time expires
        }, wait);
    }
}

const handleSearch = debounce(async (query: string) => {
    if (query != "")
        await azureStore.searchUser(query);
}, 500);

async function deleteRate(rate: UserRateItem, index: number) {
    if (rate.id) {
        state.deleteUserRateId = rate.id;
        vfm.open(confirmModalId);
    }
    else {
        props.user.rates.splice(index);
    }
}

function checkInput(index: number) {
    let input = props.user.dailyCommitments[index].hours;
    if (input) {
        if (input > 24) {
            showToastError("Day does not have more than 24 hours");
            props.user.dailyCommitments[index].hours = 0;
        } else if (input < 0) {
            showToastError("Day value cannot be bellow zero");
            props.user.dailyCommitments[index].hours = 0;
        } else if (input % 1 != 0.5 && input % 1 != 0) {
            showToastError("Only half or full hours are allowed");
            props.user.dailyCommitments[index].hours = input - (input % 1);
        } else {
            props.user.dailyCommitments[index].hours = input;
        }
    } else {
        props.user.dailyCommitments[index].hours = input;
    }
}

async function deleteVacation(vacation: VacationItem, index: number) {
    if (vacation.id) {
        userStore.deleteVacation(vacation.id);
    }
    props.user.flexiVacations.splice(index);
}

async function deleteDailyCommitment(commitment: any, index: number) {
    if (commitment.id) {
        userStore.deleteDailyCommitment(commitment.id);
    }
    props.user.dailyCommitments.splice(index);
}

async function deleteFloatingDay(vacation: VacationItem, index: number) {
    if (vacation.id) {
        userStore.deleteFloatingDay(vacation.id);
    }
    props.user.floatingDays.splice(index);
}

async function deleteOtCorrection(otItem: OvertimeFondItem, index: number) {
    if (otItem.id) {
        userStore.deleteOtFond(otItem.id);
    }
    props.user.overtimeFonds.splice(index);
}

function showProjects() {
    vfm.open(userProjectId);
}

async function selectedUser(option: any) {
    props.user.email = option.mail;
    props.user.id = option.id;
    props.user.firstName = option.givenName;
    props.user.lastName = option.surname;
    await loadManager(option.id);
}

async function loadManager(id: string) {
    await azureStore.loadUserManager(id);
    props.user.managerId = azureStore.getUserManager.id;
    props.user.managerEmail = azureStore.getUserManager.mail;
}

function addRate() {
    if (!props.user.rates) {
        props.user.rates = [];
    }
    props.user.rates.push({ userId: props.user.id })
}

function addVacation() {
    if (!props.user.flexiVacations) {
        props.user.flexiVacations = [];
    }
    props.user.flexiVacations.push({ year: (new Date()).getFullYear() })
}

function addDailyCommitment() {
    if (!props.user.dailyCommitments || props.user.dailyCommitments.length == 0) {
        props.user.dailyCommitments = [];
        let nextDate = new Date(props.user.startDate);
        props.user.dailyCommitments.push({
            fromPicker: { year: nextDate.getFullYear(), month: nextDate.getMonth() },
            toPicker: null,
            hours: 0,
            from: props.user.startDate
        });
    }
    else {
        let index = props.user.dailyCommitments.length - 1;
        let lastTo = new Date(props.user.dailyCommitments[index]?.to ?? new Date());
        let nextDate = new Date(lastTo.getFullYear(), lastTo.getMonth(), 1);
        props.user.dailyCommitments.push({
            fromPicker: { year: nextDate.getFullYear(), month: nextDate.getMonth() },
            toPicker: null,
            hours: 0,
            from: nextDate
        });
    }
}

function addFloatingDays() {
    if (!props.user.floatingDays) {
        props.user.floatingDays = [];
    }
    props.user.floatingDays.push({ year: (new Date()).getFullYear() })
}

function addOtCorrection() {
    if (!props.user.overtimeFonds) {
        props.user.overtimeFonds = [];
    }
    props.user.overtimeFonds.push({ year: (new Date()).getFullYear() })
}

function processRates() {
    props.user.rates = props.user.rates?.filter(a => a.rateId);
    props.user.rates?.forEach((b) => {
        let c = b.monthRange[0];
        b.range = [] as any;
        b.range[0] = new Date(Date.UTC(c.year, c.month));
        b.from = b.range[0];
        c = b.monthRange[1];
        b.range[1] = new Date(Date.UTC(c.year, c.month + 1, 0));
        b.to = b.range[1];
    });
}

function processDailyCommitment() {
    props.user.dailyCommitments = props.user.dailyCommitments?.filter(a => a.hours);
    props.user.dailyCommitments?.forEach((b) => {
        b.from = new Date(b.from);
        b.to = b.to ? new Date(b.to) : null;
    });
}

function createUser() {
    props.user.rates = props.user.rates?.filter(a => a.rateId);
    props.user.employeeId = props.user.employeeId ? String(props.user.employeeId) : null;
    processRates();
    processDailyCommitment();
    emit('create', props.user);
}

function updateUser() {
    props.user.rates = props.user.rates?.filter(a => a.rateId);
    props.user.employeeId = props.user.employeeId ? String(props.user.employeeId) : null;
    processRates();
    processDailyCommitment();
    emit('update', props.user);
    state.edit = false;
}
</script>

<style scoped lang="scss">
.user-detail-body {
    height: calc(100vh - 225px);
    overflow: auto;
}

.user-inp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &>div {
        min-width: 350px;
        padding-top: 5px;

        input {
            width: 288px;
            margin-left: 0;
        }

        .v-select {
            width: 300px;
        }
    }
}


.user-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-top: 0.5rem;

    :first-child {
        margin-right: 1rem;
    }

    .user-column {
        margin-left: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &>div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
        }
    }

    .v-select {
        width: 300px;
    }

    input[type=radio] {
        width: 35px;
    }

    .user-actions {
        margin-left: 55px;
    }
}

.usr-selector {
    padding-top: 5px;
}
</style>