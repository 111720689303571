import ApiConnector from "../apiConnector";
import { ReportingMonth } from "../entities/Reporting/ReportingMonth";

class ReportingDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor(baseURL: string) {
    this.apiConnector = new ApiConnector(baseURL);
  }
  public async getReportingMonth(year: number, month: number, userId: string) {
    return await this.apiConnector.get<any>(
      `reporting/${year}/${month}/${userId}`
    );
  }
  public async saveReportingMonth(repMonth: ReportingMonth) {
    return await this.apiConnector.post<void>("reporting", repMonth);
  }
  public async getHistory(year: number, month: number, userId: string) {
    return await this.apiConnector.get<any>(`reporting/history/${year}/${month}/${userId}`);
  }
}
export default ReportingDataFetcher;