<template>
  <nav>
    <router-link :to="{ name: 'AppSettings' }" :class="{ active: router.name == 'AppSettings' }">Application
      settings</router-link>
    <router-link :to="{ name: 'PublicHolidays' }" :class="{ active: router.name == 'PublicHolidays' }">Public
      holidays</router-link>
    <router-link :to="{ name: 'Services' }" :class="{ active: router.name == 'Services' }">Services</router-link>
    <router-link :to="{ name: 'Rates' }" :class="{ active: router.name == 'Rates' }">Rates</router-link>
    <router-link :to="{ name: 'Divisions' }" :class="{ active: router.name == 'Divisions' }">Divisions</router-link>
  </nav>
  <TileCard class="app-settings">
    <template #body>
      <router-view></router-view>
    </template>
  </TileCard>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import TileCard from "@/components/util/TileCard.vue";

const router = useRoute();
</script>

<style scoped lang="scss">
.app-settings {
  height: calc(100vh - 185px);
}

nav {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 0 0;
  margin-left: 24px;
  margin-bottom: -5px;
  margin-top: 15px;

  a {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    max-width: 200px;
    align-self: stretch;
    text-decoration: none;
    flex-direction: column;
    align-items: center;
    color: var(--foreground-primary, #1f1f1f);
    /* body/large/weight-bold */
    font-family: ABBvoice;
    font-size: 16px;
    font-style: normal;
    line-height: 200%;
    padding: 0px 12px;
    border-radius: 8px 8px 0 0;

    &.active {
      font-weight: 700;
      background: var(--background-base, #fff);
    }
  }
}
</style>
