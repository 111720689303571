<template>
    <Teleport to="body">
        <div class="login">
            <img :src="image" width="320" />
            <div>
                <Transition name="slide-fade">
                    <span v-if="state.status == 0">Trying to log you in...</span>
                    <span v-else-if="state.status == 1">Getting your role</span>
                    <span v-else-if="state.status == 2">Succesfully logged, you will be redirected</span>
                    <span v-else-if="state.status == -1">You need to log in with ABB account</span>
                    <span v-else-if="state.status == -2">You don't have permission to access this application</span>
                </Transition>
                <LoadingDots class="loading"></LoadingDots>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import router from "@/router";
import { Authorization, LogStatus } from "@/services/authenticate";
import { useUserStore } from "@/stores/user";
import { onMounted, reactive } from "vue"; 
import { useOverviewStore } from "@/stores/overview";
import { useSettingsStore } from "@/stores/setting";
import LoadingDots from "@/components/util/LoadingDots.vue";
import image from "@/assets/ABB-logo.svg";

const userStore = useUserStore();

const state = reactive({
    status: 0
});

onMounted(async () => {
    loggedCheck(Authorization.logStatus);
    state.status = 0;
    await Authorization.signIn();
    if (Authorization.logStatus == LogStatus.Logged) {
        state.status = 1;
        await userStore.loadUserRole();
        if (userStore.getUserRole > 0) {
            state.status = 2;
            if (userStore.getUserRole > 0) {
                await getBasicData();
                loggedCheck(Authorization.logStatus);
            }
        }
        else {
            state.status = -2;
        }
    }
    else {
        state.status = -1;
    }
});

async function getBasicData() {
    const overviewStore = useOverviewStore();
    const settingsStore = useSettingsStore();

    const promises = [
        overviewStore.loadProjectTypes(),
        overviewStore.loadNonBillableProjectType(),
        overviewStore.loadInvoiceTypes(),
        settingsStore.loadCountries(),
        settingsStore.loadDivisions(),
    ];
    await Promise.all(promises);
}

function loggedCheck(value: LogStatus) {
    if (value == LogStatus.Logged) {
        const destination = sessionStorage.getItem("log_to") ?? 'ReportHours';
        sessionStorage.removeItem("log_to");
        router.push({ name: destination == 'Login' ? 'ReportHours' : destination });
    }
}
</script>

<style scoped lang="scss">
.login {
    top: 0;
    background: var(--foreground-white);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .loading {
        margin-top: 80px;
    }

    img {
        margin-top: 50px;
    }

    &>div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        & span {
            font-size: 22px;
            width: 545px;
            position: absolute;
        }
    }
}
</style>
<style lang="scss">
.slide-fade-enter-active {
    transition: all 1s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
    transform: translateX(-400px);
}

.slide-fade-leave-to {
    transform: translateX(400px);
    opacity: 0;
}
</style>