<template>
    <slot name="header"></slot>
    <div class="create-form">
        <div>
            <label for="i_name">Service Name<b v-if="!props.value?.blServiceName">*</b></label>
            <input id="i_name" type="string" v-model="props.value.blServiceName" />
        </div>
        <div>
            <label for="i_code">BL PG Code<b v-if="!props.value.blpgCode">*</b></label>
            <input id="i_code" type="string" v-model="props.value.blpgCode" />
        </div>
        <div>
            <label for="i_sales">Sales order<b v-if="!props.value.mxSalesOrder">*</b></label>
            <input id="i_sales" type="string" v-model="props.value.mxSalesOrder" />
        </div>
        <div>
            <label for="i_order">Order value<b v-if="!props.value.orderValue">*</b></label>
            <input id="i_order" type="number" v-model="props.value.orderValue" />
        </div>
        <div>
            <label for="i_po">PO number<b v-if="!props.value.poNumber">*</b></label>
            <input id="i_po" type="string" v-model="props.value.poNumber" />
        </div>
        <div>
            <label for="i_op_manager">Operations manager<b v-if="!props.value.operationsManager">*</b></label>
            <input id="i_op_manager" type="string" v-model="props.value.operationsManager" />
        </div>
        <div>
            <label for="i_invoice">Invoice address<b v-if="!props.value.invoiceAddress">*</b></label>
            <input id="i_invoice" type="string" v-model="props.value.invoiceAddress" />
        </div>
        <div>
            <label for="i_sap">SAP number<b v-if="!props.value.sapNumber">*</b></label>
            <input id="i_sap" type="string" v-model="props.value.sapNumber" />
        </div>
        <div>
            <label for="i_from">Valid from<b v-if="!props.value.validFrom">*</b></label>
            <VueDatePicker :teleport="true" id="i_from" :enable-time-picker="false" v-model="props.value.validFrom" />
        </div>
        <div>
            <label for="i_to">Valid to<b v-if="!props.value.validTo">*</b></label>
            <VueDatePicker :teleport="true" id="i_to" :enable-time-picker="false" v-model="props.value.validTo" />
        </div>
        <div>
            <label for="i_division">Division<b v-if="!props.value.divisionId">*</b></label>
            <vue-select :appendToBody="true" label="name" :options="settingsStore.getDivisions" :clearable="false"
                v-model="props.value.divisionId" :reduce="(item) => item.id" />
        </div>
        <div>
            <label for="i_country">Country<b v-if="!props.value.countryId">*</b></label>
            <vue-select :appendToBody="true" label="name" :options="settingsStore.getCountries" :clearable="false"
                v-model="props.value.countryId" :reduce="(item) => item.id" />
        </div>
        <div>
            <label for="i_link">Link</label>
            <input id="i_link" type="url" v-model="props.value.link" />
        </div>
        <div class="full-width">
            <label for="i_description">Description</label>
            <textarea id="i_description" v-model="props.value.description" />
        </div>
    </div>
    <div class="buttons">
        <slot name="buttons"></slot>
    </div>
</template>

<script setup lang="ts">
import { ServiceItem } from "@/services/entities/ServiceItem";
import { useSettingsStore } from "@/stores/setting";
import VueSelect from "vue-select";

const settingsStore = useSettingsStore();

const props = defineProps({
    value: { Type: {} as ServiceItem, default: {} as ServiceItem }
});
</script>

<style scoped lang="scss">
label b {
    color: var(--status-error);
    margin-left: 0.1rem;
}

.create-form>div {
  max-width: 750px;
  min-width: 400px;
  padding-bottom: 5px;

  input {
    width: 240px;
  }

  .v-select {
    width: 250px;
  }
}
</style>