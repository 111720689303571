import { defineStore } from "pinia"
import { getServerUrl } from "@/common/urls";
import { LockHistoryItem } from "@/services/entities/LockHistoryItem"
import LockDataFetcher from "@/services/connectors/lockConnector";
import { closeToast, showLoading, showSuccess, showToastError } from "@/toastification";
import { parseError } from "@/common/util";

const lock = new LockDataFetcher(getServerUrl());

export const useLockItemsStore = defineStore("lockHistoryItem", {
    state: () => ({
        lockItems: {
            data: [] as LockHistoryItem[],
            loader: false,
        },
    }),
    actions: {
        async loadLockItems(
            year: number,
            month: number,
            userId: string
        ): Promise<void> {
            try {
                this.lockItems.loader = true;
                this.lockItems.data = await lock.getLockHistoryItems(year, month, userId);
            } catch (e: any) {
                this.lockItems.loader = false;
            }
        },
        async saveLockItems(
            lItems: LockHistoryItem[],
            userId: string,
            actionAuthor: string
        ): Promise<void> {
            try {
                const toast = showLoading("Locking intervals");
                await lock.saveLockHistoryItems(lItems, userId, actionAuthor);
                closeToast(toast);
                showSuccess("Intervals locked");
            } catch (e: any) {
                showToastError("Failed to lock intervals: " + parseError(e));
                console.error(e);
            }
        }
    },
    getters: {
        getAll(): LockHistoryItem[] {
            return this.lockItems.data;
        },
        getAllLoaded(): boolean {
            return !this.lockItems.loader;
        }
    },
});