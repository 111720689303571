import { getServerUrl } from "@/common/urls";
import { downloadExcelFromApi } from "@/common/util";
import ReportDataFetcher from "@/services/connectors/reportConnector";
import { InvoiceModel } from "@/services/entities/Reporting/InvoiceModel";
import { TSInvoiceItem } from "@/services/entities/TSInvoiceItem";
import { defineStore } from "pinia";

const report = new ReportDataFetcher(getServerUrl());

export const useReportStore = defineStore("report", {
  state: () => ({
    invoice: {
      data: {} as InvoiceModel,
      loader: true
    }
  }),
  actions: {
    async downloadR06(year: number) {
      const result = await report.getR06(year);
      downloadExcelFromApi(result, "MX-" + year + "_R06.xlsx");
    },
    async downloadMonthly(year: number, month: number, weekly: boolean, daily: boolean) {      
      const result = await report.getMonthly(year, month, weekly, daily);
      downloadExcelFromApi(result, "MX-" + year + "_" + month + "_Month_report.xlsx");
    },
    //mi codigo
    async downloadSap(year: number, month: number, weekly: boolean){
      const result = await report.getSap(year,month,weekly)
      downloadExcelFromApi(result, "MX-" + year + "_" + month +  "_" +weekly +"_SAP_report.xlsx");
    }, 

    //aqui acaba

    
    async loadInvoiceData(serviceId: number, year: number, month: number){
      this.invoice.loader = true;
      const result = await report.getDataForInvoice(serviceId, year, month);
      this.invoice.data = result;
      this.invoice.loader = false;
    },
    async downloadMonthlyUser(year: number, month: number, userId: string, userName: string) {
      const result = await report.getMonhtlyUser(year, month, userId);
      downloadExcelFromApi(result, "MX-" + year + "_" + month + "_" + userName +".xlsx");
    },
  },
  getters: {
    getInvoiceData(): InvoiceModel{
      return this.invoice.data
    },
    isInvoiceLoaded(): boolean {
      return !this.invoice.loader;
    }
  },
});
